module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","titleTemplate":"%s | Styllar™","title":"Styllar™","copyrights":"©2022 Styllar™ All rights reserved","openGraph":{"type":"website","locale":"en_CA","images":[{"url":"https://res.cloudinary.com/styllar/image/upload/v1632190061/Styllar-Social-SEO-META_mjowtn.png"}]},"twitter":{"handle":"@STYLLARNFT","site":"@STYLLARNFT","cardType":"summary_large_image"}},
    },{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","custom":[{"name":["ES Allianz"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
