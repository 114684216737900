import Component from './Hero.component'

import { MapBloks } from '../../bloks'
import { Rte, NewlineText } from '../../atoms'
import { useData } from '../../hooks'

const Hero = ({ blok, sanitize, ...props }) => {
  const {
    title,
    copyrights,
    specs: { list = [] },
  } = useData()?.settings ?? {}

  const { images, links, _uid } = blok ?? {}

  return (
    <div data-column data-anchor="hero" {...sanitize(props)}>
      <Component
        uid={_uid}
        head={[
          <Rte content={title} />,
          <NewlineText text={list.map(({ text }) => text).join('\n')} id="hero" />,
          <MapBloks items={copyrights} />,
        ]}
        assets={images}
        links={links}
      />
    </div>
  )
}

export default Hero
