import { Image } from '@boiler/image'
import { Hls, useHlsPlayer } from '@boiler/hls'
import { Lazy } from '@boiler/intersection-observer'
import { useBreakpoint } from '@boiler/utils'

const Video = ({
  blok,
  ratio,
  fit,
  __root,
  isActive = false,
  autoplay = true,
  eager = false,
  prevent_scope,
  fade = true,
  options = {},
  lazy = {},
  ...props
}) => {
  const { m3u8 = '', mp4 = '', image, use_static_fallback = [] } = blok ?? {}

  const [playerRef] = useHlsPlayer()

  const { breakpoint } = useBreakpoint()

  const disable_video =
    typeof use_static_fallback !== 'string'
      ? breakpoint?.name != null
        ? use_static_fallback?.some((i) => i === breakpoint?.name || i === breakpoint?.device)
        : false
      : false

  return (
    <Lazy inview={{ once: false }} options={__root != null ? { root: __root } : {}} eager={eager}>
      {({ ref, inview }) => (
        <Hls
          ref={ref}
          src={!!!disable_video && !!inview && m3u8}
          mp4={!!!disable_video && !!inview && mp4}
          playerRef={playerRef}
          onClick={playerRef?.current?.handleStart}
          styles={{ fit, ratio, fade: true }}
          options={{
            autoplay,
            playsinline: true,
            controls: false,
            loop: true,
            pip: false,
          }}
          {...props}
        >
          {!!disable_video ? (
            <Image data={image} inview={inview} object={{ fit: 'cover', position: 'center center' }} fitted inset />
          ) : (
            <Image
              data={image}
              inview={true}
              object={{ fit: 'cover', position: 'center center' }}
              placeholderOnly={true}
              placeholder={true}
              fitted
              inset
            />
          )}
        </Hls>
      )}
    </Lazy>
  )
}

export default Video
