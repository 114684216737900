const exit = 0.4
const duration = 0.65
const ease = {
  quartOut: [0.25, 1, 0.5, 1],
}

export default {
  duration,
  exit,

  __default: 'fade',
  home: 'fade',
  page: 'fade',
  project: 'fade',
  projects: 'fade',

  // compount transitions
  // projects_project: 'fade',
  // project_projects: 'fade',
  // home_projects: 'fade',

  effects: {
    fade: {
      initial: {
        y: '1.2rem',
        opacity: 0,
        willChange: 'auto',
        pointerEvents: 'none',
      },
      enter: {
        y: 0,
        opacity: 1,
        transition: { duration: duration, delay: 0.005, ease: ease.quartOut },
        transitionEnd: {
          pointerEvents: 'all',
        },
      },
      exit: {
        y: '1.2rem',
        opacity: 0,
        pointerEvents: 'none',
        transition: { duration: exit, ease: 'easeIn' },
      },
    },
    presence: {
      initial: {
        y: '1.2rem',
        opacity: 0,
        willChange: 'auto',
        pointerEvents: 'none',
      },
      enter: {
        y: 0,
        opacity: 1,
        transition: { duration: duration, ease: ease.quartOut },
        transitionEnd: {
          pointerEvents: 'all',
        },
      },
      exit: {
        y: 0,
        opacity: 0,
        pointerEvents: 'none',
        transition: { duration: exit, ease: 'easeIn' },
      },
    },
    cycle: {
      initial: {
        opacity: 0,
        pointerEvents: 'none',
      },
      enter: {
        opacity: 1,
        transition: { duration: 0.1, ease: ease.quartOut },
        transitionEnd: {
          pointerEvents: 'all',
        },
      },
      exit: {
        opacity: 0,
        pointerEvents: 'none',
        transition: { duration: 0.1, ease: 'easeIn' },
      },
    },

    freeze: {
      initial: false,
      enter: {
        opacity: 1,
        transition: { duration: duration * 2, delay: duration * 2 },
      },
      exit: {
        opacity: 1,
        transition: { duration: duration * 2, delay: 0 },
      },
    },
  },
}
