import { transitions } from 'polished'

const transition = (extend = []) => transitions(['color', 'background-color', ...extend], 'var(--speed) var(--ease)')
const variant = 'text.cta'

export default {
  __default: {
    borderWidth: 1,
    borderColor: 'text',
    borderStyle: 'solid',
    borderLeft: 'none',
    borderRight: 'none',

    '--icon-size': '0.8em',

    '& + &': {
      borderTop: 'none',
    },

    '&[data-disabled=true]': { cursor: 'not-allowed' },

    '@media (hover: hover)': {
      '&:hover': {
        '--bullet-color': (t) => t.colors.text,
      },
      '&[data-selected=true][data-static=false]': { px: '0.75rem' },
    },
    '&[aria-expanded=true],&[data-selected=true]': {
      '--bullet-color': (t) => t.colors.text,
    },

    ...transition(['padding-left', 'padding-right']),
  },

  rte: {
    mt: '1em',
    mb: '2em',

    display: 'grid',
    gridTemplateColumns: ['1fr var(--icon-size)', '1fr 1fr var(--icon-size)'],
    gap: 1,
    '> *': {
      gridColumn: ['1/2', 'span 1'],
    },
    '&[data-multiple=false] > *': {
      gridColumn: ['1/2', 'span 2'],
      maxWidth: '850px',
    },
  },

  service_list: {
    article: {
      mt: '1em',
      mb: '2em',

      display: 'grid',
      gridTemplateColumns: ['1fr var(--icon-size)', '1fr 1fr var(--icon-size)'],
      gap: ['3rem', 1],

      '> *': {
        gridColumn: ['1/2', 'span 1'],
      },
      '&[data-multiple=false] > *': {
        gridColumn: ['1/2', 'span 2'],
        maxWidth: '850px',
      },

      '[data-element="list"]': {
        gap: '0.7rem',
        alignItems: 'flex-start',
      },
      '[data-element="content"]': {
        gap: '3rem',
        alignItems: 'flex-start',
      },
    },
  },

  jobs_list: {
    article: {
      mt: '1em',
      mb: '2em',

      display: 'grid',
      gridTemplateColumns: ['1fr var(--icon-size)', '1fr 1fr var(--icon-size)'],
      gap: ['3rem', 1],

      '> *': {
        gridColumn: ['1/2', 'span 1'],
      },
      '&[data-multiple=false] > *': {
        gridColumn: ['1/2', 'span 2'],
        maxWidth: '850px',
      },

      '[data-element="list"]': {
        gap: '0.7rem',
        alignItems: 'flex-start',
      },
      '[data-element="content"]': {
        gap: '3rem',
        alignItems: 'flex-start',
      },
      '[data-element="sep"]': {
        width: '100%',
        borderTop: (t) => `var(--stroke-x) solid ${t?.colors?.highlight}`,
      },
    },
  },

  head: {
    width: '100%',
    position: 'relative',

    gridTemplateColumns: 'repeat(var(--length, 1), 1fr) var(--icon-size)',
    gridColumnGap: 1,
    gridRowGap: 0,

    '&[data-standalone=true]': {
      gridTemplateColumns: 'repeat(calc(var(--length, 1) - 1), 1fr) auto',
    },
  },

  text: {
    variant,

    width: '100%',
    py: '1.5em',
    gridColumn: 'span 1',
  },

  icon: {
    position: 'absolute',
    gridColumn: 'calc(var(--length, 1) + 1)/-1',
    size: '100%',

    alignItems: 'center',

    aside: {
      '--size': 'var(--icon-size)',
      position: 'absolute',
      size: 'var(--size)',

      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'var(--bullet-color, unset)',

      bg: 'var(--bullet-color, unset)',

      ...transition(['transform', 'border-color']),
    },
  },
}
