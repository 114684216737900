import SbEditable from 'storyblok-react'
import { Section, Container, Heading as Text } from '@monobits/components'

import { Rte } from '../atoms'

const Heading = ({ blok, children, cProps = {}, sanitize = (p) => p, ...props }) => {
  const { caption, content, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section variant="heading" {...cProps}>
        <Container {...sanitize(props)}>
          <Text
            as="h3"
            variant="caption"
            sx={{
              position: 'absolute',
              maxWidth: 'calc(var(--text-indent) - var(--padding-x))',
              top: 0,
            }}
          >
            {caption}
          </Text>
          <Rte as="h2" variant="title" content={content} indented />
        </Container>
      </Section>
    </SbEditable>
  )
}

export default Heading
