import { multiLink } from '@boiler/utils'
import { Underline, MenuItem } from '../atoms'

const NavItem = ({ children, blok, type = 'nav', ...props }) => {
  const { link, params, text, locale, disabled } = blok ?? {}

  return {
    menu: (
      <MenuItem {...multiLink(link, params)} disabled={disabled} {...props}>
        <span>{text || children}</span>
      </MenuItem>
    ),
    nav: (
      <Underline variant="caption" {...props} {...multiLink(link, params)}>
        {text || locale || children}
      </Underline>
    ),
  }[type]
}

export default NavItem
