import { useState, forwardRef } from 'react'
import { Flex } from 'theme-ui'

import { useImage } from './ImageProvider'
import { getImageProps, sdk } from './sdk'
import { useResponsive } from './responsive'
import { MEDIA_DEFAULTS } from '../__consts'

/**
 * Image Component leveraging Storyblok's images service (CDN).
 * Use the provider to set default configurations to the context.
 * Makes images responsive without all the heavy lifting.
 *
 * @param {Object} data Storyblok image blok data
 * @param {Object} params Storyblok Image CDN transformation filters
 * @param {Object} object `object-fit` values
 * @param {Object} childProps The <img /> props
 * @param {Object} observer Intersection Observer options
 * @param {func} onLoad Image loaded event
 * @param {number} fixed Fixed width in px, if set to 0 the with will be responsive
 * @param {string|number} ratio Aspect ration, acceps strings '1/1' and integrers 0.56
 * @param {string} position CSS object-position
 * @param {Boolean} fitted Is the image fitted in it's container ?
 * @param {Boolean} placeholder Should a placeholder be displayed ?
 * @param {Boolean} fade Should the image fade-in once loaded ?
 * @param {Boolean} inset Should the image fill its container ?
 */
const StoryblokImage = forwardRef(
  (
    {
      data = {},
      params = {},
      object = {},
      inview = null,
      childProps = {},
      observer = {},
      onLoad = () => {},
      fixed = null,
      ratio: _ratio = null,
      fitted = false,
      inset = false,
      lazyload = true,
      placeholder = null,
      placeholderOnly = false,
      position = null,
      fade = null,
      sx,
      ...props
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)

    const image = getImageProps(data)
    const medias = useImage() ?? {}

    const _params = { ...MEDIA_DEFAULTS?.params, ...medias?.params, ...params }
    const _object = { ...MEDIA_DEFAULTS?.object, ...medias?.object, ...object }
    const _placeholder = placeholder ?? medias?.placeholder ?? MEDIA_DEFAULTS?.placeholder
    const _fade = fade ?? medias?.fade ?? MEDIA_DEFAULTS?.fade
    const _transition = medias?.transition ?? MEDIA_DEFAULTS?.transition
    const placeholder_modifiers = medias?.placeholder_modifiers ?? MEDIA_DEFAULTS?.placeholder_modifiers

    const ratio = !!_ratio ? _ratio : null

    const transformer = (width) => sdk(image, { width, placeholder_modifiers, ..._params })

    const imgRef = useResponsive(
      fixed ?? 0,
      {
        placeholderOnly,
        steps: medias.steps,
        fitted: [fitted, _object.fit],
        height_ratio: image?.height / image?.width,
        max_width: image?.width ?? null,
        transformer,
        placeholder_node: (node) => {
          const parent = node?.parentNode?.childNodes ?? []
          return parent?.length >= 2 ? parent?.[0] : null
        },
      },
      [inview]
    )

    const handleLoaded = () => {
      setLoaded(true)
      onLoad()
    }

    return (
      <Flex
        ref={ref}
        data-img
        data-aspect-ratio
        data-loaded={!!loaded}
        data-inset={!!inset}
        data-with-transition={!!_fade}
        style={{
          '--object-fit': _object.fit,
          '--object-position': _object.position,
          '--aspect-ratio': ratio ?? image?.aspect_ratio,
          '--transition': _transition,
        }}
        sx={{ variant: 'image', ...sx }}
        {...props}
      >
        {!!_placeholder && (
          <img
            onLoad={(e) => e?.target?.setAttribute('data-loaded', true)}
            data-loaded={false}
            data-placeholder
            alt=""
          />
        )}
        <img ref={imgRef} alt={data?.alt} onLoad={handleLoaded} data-image {...childProps} />
      </Flex>
    )
  }
)

export default StoryblokImage
