import { Flex } from 'theme-ui'
import { Icons, Rte } from '../atoms'

const NotFound = ({ blok }) => {
  const { long_title, caption } = blok ?? {}

  return (
    <Flex
      sx={{
        '--new-mt': 'calc(var(--padding-m) + var(--header-height))',
        '--new-height': 'calc((var(--vh, 1vh) * 100) - var(--new-mt))',
        minHeight: 'var(--new-height)',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        mt: 'var(--new-mt)',
        mb: 2,
        pb: 1,
      }}
      data-view="page"
    >
      <hr />
      <aside sx={{ variant: 'promo.head', pt: 1, px: 1, pb: 2 }}>
        <Rte data-scope="caption" content={long_title} variant="text" />
        <Rte data-scope="description" content={caption} variant="text" />
      </aside>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        <div sx={{ px: 1 }}>
          <Icons name="404" />
        </div>
      </div>
    </Flex>
  )
}

export default NotFound
