import { omit } from 'lodash'
import { SEO } from '../bloks'
import useStoryblok from '../layouts/useStoryblok'
import Template from '../templates'

const BaseEntry = ({ pageContext, location, seo = () => {}, ...props }) => {
  let { story, settings, locales } = useStoryblok(pageContext, location)

  const storyblok = { blok: story.content, source: story, settings, locales }

  return (
    <>
      <SEO {...storyblok} {...seo(story)} pageContext={pageContext} />
      <Template
        {...storyblok}
        tags={pageContext?.tags || []}
        component={story.content.component}
        key={pageContext.story._uid}
        realPath={pageContext.realPath}
        location={location}
        sanitize={(obj) =>
          omit(obj, [
            ...Object.keys(pageContext),
            ...Object.keys(storyblok),
            ...Object.keys(props),
            'location',
            'tags',
            //
          ])
        }
        {...props}
      />
    </>
  )
}

export default BaseEntry
