import { useRef, useState, useEffect } from 'react'
import { Section, Text } from '@monobits/components'
import { useTween } from '@monobits/gsap'
import { useConditionalEffect } from '@react-hookz/web'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { InertiaPlugin } from 'gsap/InertiaPlugin'
import { useMousePos } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import { Rte } from '../../atoms'

import Card from './Card'
// import { useTween } from '../../hooks'

const BASE = {
  cursor: 'none',
  activeCursor: 'none',
  inertia: true,
  dragClickables: true,
  allowContextMenu: true,
  overshootTolerance: 0.6,
  edgeResistance: 0.8,
}

const Slider = ({ blok, settings = {}, locales: { locales } = {} }) => {
  const { caption, aspect_ratio, items, _uid } = blok ?? {}

  let ref = useRef(null)
  let fade = useRef(null)

  const breakpoint = useBreakpointIndex()

  const [press, setPress] = useState(false)

  const [tween, cursor] = useTween(({ node }) =>
    gsap
      .timeline({ paused: true, defaults: { duration: 0.45, delay: 0.15, ease: 'power3.inOut', overwrite: 'auto' } })
      .fromTo(node.childNodes, { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
  )

  const [active] = useTween(
    ({ node }) =>
      gsap
        .timeline({ paused: true, defaults: { duration: 0.35, ease: 'expo.inOut' } })
        .fromTo(node.childNodes[0], { scale: 1, opacity: 1 }, { scale: 0.45, opacity: 0.35 }, 0)
        .to(node.childNodes[1], { autoAlpha: 0 }, 0),
    [],
    [],
    cursor
  )

  const {
    is,
    hover: [hover, setHover],
  } = useMousePos([cursor.current?.parentNode, cursor.current], {
    options: { lerp: 0.45 },
  })

  useConditionalEffect(
    () => {
      if (hover) tween.play()
      if (!hover) tween.reverse()
    },
    [hover],
    [!is.touch, tween]
  )
  useConditionalEffect(
    () => {
      if (press) active.play()
      if (!press) active.reverse()
    },
    [press],
    [!is.touch, active]
  )

  const init = () => {
    const row = gsap.getProperty(ref.current)

    const animation = gsap.fromTo(fade.current, { autoAlpha: 1 }, { autoAlpha: 0, paused: true })

    const draggable = new Draggable.create(ref.current, {
      type: 'x',
      bounds: ref.current.parentNode,
      onThrowUpdate: updateProgress,
      onDrag: updateProgress,
      ...BASE,
    })[0]

    function updateProgress(e) {
      if (animation && breakpoint !== 0) {
        const p = Math.round(((row('x') / draggable.minX) * 1000) / 10) * 0.01
        const progress = Math.abs(gsap.utils.clamp(0, 1, p))

        animation.progress(progress * 10)
      }
    }
  }

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)
    init()
  }, [breakpoint]) // eslint-disable-line

  return (
    <SbEditable content={blok} key={_uid}>
      <Section variant="content" sx={{ variant: 'slider' }}>
        <div sx={{ variant: 'slider.wrapper', '--aspect-ratio': aspect_ratio || '1/1' }}>
          <div ref={fade} sx={{ variant: 'slider.caption' }}>
            <Rte content={caption || (settings?.title ?? {})} />
          </div>

          <div
            sx={{ variant: 'slider.content' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={() => setPress(true)}
            onMouseUp={() => setPress(false)}
          >
            <div sx={{ variant: 'slider.cursor' }} ref={cursor.ref}>
              <div />
              <Text variant="cursor">{locales?.['drag'] ?? 'Drag'}</Text>
            </div>
            <aside ref={ref}>
              {items.map((props, i) => (
                <Card
                  key={props._uid + i}
                  ratio={aspect_ratio}
                  bindHover={{
                    onMouseEnter: () => setHover(false),
                    onMouseLeave: () => setHover(true),
                  }}
                  {...props}
                />
              ))}
            </aside>
          </div>
        </div>
      </Section>
    </SbEditable>
  )
}

export default Slider
