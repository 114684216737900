import { useUpdateEffect } from '@react-hookz/web'
import { Link as GatsbyLink } from 'gatsby'
import { withHover } from '@monobits/core'
import { Section } from '@monobits/components'

import { useComponent } from '../hooks'

const Cell = withHover(
  ({
    children,
    title,
    id,
    isStatic = false,
    showIcon,
    link = '',
    sx,
    onHover = () => {},
    hover,
    disabled,
    isSelected = null,
    ...props
  }) => {
    const { collapsible } = useComponent() ?? {}
    const isLink = !!link

    useUpdateEffect(() => onHover && onHover(!!hover), [hover])

    return collapsible.head({
      sx: { '--speed': 'var(--ui-duration)', '--ease': 'var(--ui-ease)', ...sx },
      props: {
        wrapper: {
          'data-handle': id,
          'data-selected': isSelected || !!hover,
          'data-disabled': !!disabled,
          'data-static': !!isStatic,
          ...props,
        },
        button: isLink ? { as: GatsbyLink, to: link } : {},
      },
      value: title,
      showIcon: showIcon ?? !!isLink,
      isLink,
      link,
      id,
    })
  },
  { mouse: true }
)

const Table = ({ items, id = '', children, ...props }) => {
  const { collapsible } = useComponent() ?? {}

  return (
    <Section {...collapsible.section} data-single={true} {...props}>
      <div>{children}</div>
    </Section>
  )
}

Table.Cell = Cell

export default Table
