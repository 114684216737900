import { Fragment } from 'react'
import { useRecoilState } from 'recoil'
import { willChangeEvents } from '@monobits/gsap'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'
import { getSmoother } from '@boiler/smoother'
import ScrollLock from 'react-scrolllock'

import { menuOpenState } from '../../states'

import { InvertedMode } from '../../atoms'
import { useTween } from '../../hooks'
import Header from '../Header'

const smoothPaused = (bool) => getSmoother()?.paused(bool)

const Menu = ({ navigation, secondary, watch }) => {
  const [openned, setOpenned] = useRecoilState(menuOpenState)

  const [tween, element] = useTween(({ gsap, node }) => {
    return gsap
      .timeline({
        paused: true,
        defaults: {
          duration: 0.8,
          ease: 'expo.inOut',
          ...willChangeEvents(node),
        },
      })
      .to(node, { pointerEvents: 'auto', duration: 0 })
      .add('start')
      .to(node, { '--clip-path': '0%' }, 'start')
  })

  useConditionalEffect(
    () => {
      if (openned) {
        smoothPaused(true)
        tween.play()
      }
      if (!openned) {
        tween.reverse()
        smoothPaused(false)
      }
    },
    [openned, tween],
    [tween != null],
    undefined,
    useUpdateEffect
  )

  useUpdateEffect(() => {
    setOpenned(false)
  }, watch ?? [])

  return (
    <section ref={element.ref} sx={{ variant: 'menu' }}>
      <InvertedMode data-column sx={{ variant: 'menu.provider' }}>
        <Header isNested={true} />
        <ScrollLock isActive={openned || false}>
          <nav data-column sx={{ height: '100%' }}>
            {navigation.map((node, i) => (
              <Fragment key={i + 'menu'}>{node}</Fragment>
            ))}
          </nav>
        </ScrollLock>
        {secondary && (
          <aside sx={{ variant: 'menu.foot' }}>
            {secondary.map((node, i) => (
              <Fragment key={i + 'menusec'}>{node}</Fragment>
            ))}
          </aside>
        )}
      </InvertedMode>
    </section>
  )
}

export default Menu
