import { Heading } from '@monobits/components'
import { MapBloks } from '../bloks'

const ContentGroup = ({ blok, video = {}, fitted, sanitize = (p) => p, ...props }) => {
  const { body, caption, _uid } = blok ?? {}

  return (
    <div sx={{ variant: 'faq.group' }}>
      <Heading as="h3" variant="text">
        {caption}
      </Heading>

      <div sx={{ gap: 'var(--rte, 1em)' }} data-column>
        <MapBloks
          key={_uid}
          id={_uid}
          items={body}
          childProps={{
            asset: { fitted, ...video },
          }}
          {...sanitize(props)}
        />
      </div>
    </div>
  )
}

export default ContentGroup
