export default {
  __default: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 'popup',

    width: ['100%', '85%', 'clamp(800px, 50%, 1400px)'],
    height: '100%',

    bg: 'dark',
    color: 'light',

    visibility: 'hidden',
    clipPath: 'inset(0 0 0 var(--clip-path, 100%))',
    '--clip-path': '100%',

    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',

    backfaceVisibility: 'hidden',
    transform: 'translateZ(0)',
  },
}
