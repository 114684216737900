import { memo } from 'react'
import { useSmoothEffect } from '@boiler/smoother'
import { useBreakpoint } from '@boiler/utils'

import { MapBloks } from '../../bloks'

const Asset = ({ blok, wrapperProps, playing = null, __root = null, persistent = false, ...props }) => {
  const { asset, _uid, ratio, multi_ratio, fit, align_x, align_y, smooth, offset } = blok ?? {}

  const list = multi_ratio?.list ?? []
  const get_ratio = list?.map((i) => (i?.text === 'default' ? null : i?.text))?.filter(Boolean)
  const has_ratio = !!get_ratio?.length
  const ratios = has_ratio ? get_ratio : !!ratio ? ratio : null
  const position = `${align_x ?? 'center'} ${align_y ?? 'center'}`

  const { index } = useBreakpoint()

  const [ref, attributes] = useSmoothEffect(
    {},
    { speed: !!!persistent ? (index != null && index > 1 ? smooth?.value : null) : smooth?.value },
    [persistent],
    []
  )

  const element = (
    <MapBloks
      id={_uid}
      items={asset}
      childProps={{
        image: { fit, ratio: ratios, position, __root },
        video: {
          fit,
          ratio: ratios,
          position,
          playing,
          __root,
          // autoplay: true,
          // eager: i === 0,
          lazy: { play: true, once: false },
        },
        lottie: { autoplay: true, loop: true },
      }}
      {...props}
    />
  )

  // if (!!(component === 'asset-plus') && blok?.distance?.value !== 0) {
  //   return (
  //     <Motion distance={blok?.distance?.value} json={blok?.json ?? '{}'}>
  //       {element}
  //     </Motion>
  //   )
  // }

  return (
    <div ref={ref} style={{ marginTop: (offset?.value ?? 0) + 'rem' }} {...attributes} {...wrapperProps}>
      {element}
    </div>
  )
}

export default memo(Asset)
