const selector = 'html'
// const parseBoolean = (string) => ({ true: true, false: false }[string])

const getRoot = (key = '') => {
  const element = document.querySelector(selector)
  const current_value = element?.getAttribute(key)
  return { element, current_value }
}

const getMargins = (value, [viewportHeight, scrollHeight]) =>
  ({
    string: () => {
      const percent = +value?.replace('%', '') * 0.01
      const top = viewportHeight * percent
      const bottom = scrollHeight - top
      return { top, bottom, percent }
    },
    number: () => ({
      top: value,
      bottom: scrollHeight - value,
      percent: value / (scrollHeight + viewportHeight),
    }),
  }[typeof value])

const getPosition = (obj = {}) =>
  Object.entries(obj)
    ?.map(([k, v]) => (!!v ? k : false))
    ?.filter(Boolean)?.[0] ?? 'scrolled'

const getScrollRect = (get = () => {}) => {
  const wrapper = get('wrapper')
  const content = get('content')

  const viewportHeight = window.innerHeight ?? 0
  const contentHeight = content?.clientHeight ?? 0
  const strict = contentHeight <= viewportHeight + 2
  const allowed = contentHeight >= viewportHeight * 2

  return {
    strict,
    allowed,
    viewportHeight,
    contentHeight,
    elements: {
      wrapper,
      content,
    },
  }
}

export const scrollPosition = (self, { get = () => {}, rootMargin = '80%', key = 'data-scroll-position' } = {}) => {
  const { element, current_value } = getRoot(key)
  const { strict, allowed, viewportHeight } = getScrollRect(get)
  const { progress = 0, end: scrollHeight = 0 } = self ?? { progress: 0, end: 0 }

  const scrollY = scrollHeight * progress

  const { top, bottom, percent } = getMargins(rootMargin, [viewportHeight, scrollHeight])()

  const position =
    !!!allowed && !!strict
      ? 'top'
      : getPosition(
          !!allowed
            ? { top: scrollY <= top, bottom: scrollY >= bottom }
            : { top: progress <= percent, bottom: progress >= 1 - percent }
        )

  if (element != null && current_value !== position) {
    element.setAttribute(key, position)
  }
}

export const onStartUpdate = (self, { get = () => {}, key = 'data-scroll-init', is_smooth = false, ...props } = {}) => {
  const { element, current_value } = getRoot(key)
  const { strict } = getScrollRect(get)

  element.setAttribute('data-scroll-smooth', is_smooth)

  if (('onUpdate' in props && current_value == null) || !!strict) {
    element.setAttribute(key, true)
    props?.onUpdate(self)
  }
}

export const scrollDirection = (self, { key = 'data-scroll-direction' } = {}) => {
  const { element, current_value } = getRoot(key)

  const direction = self?.direction ?? 1
  const value = { 1: 'down', '-1': 'up' }[direction?.toString()]

  if (element != null && current_value !== value) {
    element.setAttribute(key, value)
  }
}

export const isScrolling = (bool = false, { key = 'data-scroll-active' } = {}) => {
  const { element, current_value } = getRoot(key)

  if (element != null && current_value !== bool?.toString()) {
    element.setAttribute(key, current_value != null ? !!bool : false)
  }
}
