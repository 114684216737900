import {
  render,
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_LI,
  MARK_UNDERLINE,
  MARK_LINK,
} from 'storyblok-rich-text-react-renderer'
import { Text } from '@monobits/components'

import Link from './Link'
import TextIndent from './TextIndent'
import Underline from './Underline'
import { Bloks } from '../bloks'

const Rte = ({ children: nodes, head, content, sx, as, variant = 'text', hardColor, indented = false, ...props }) => {
  const cleanup = content?.content?.filter((i) => 'content' in i)
  const validContent = !!content && !!cleanup?.length

  return validContent || nodes ? (
    <div sx={{ variant: 'rte', ...sx }} as={as} {...props}>
      {head}
      {validContent &&
        render(content, {
          nodeResolvers: {
            [NODE_HEADING]: (children, { level, ...props }) => {
              const options = [
                { variant: 'title', as: 'h2' },
                { variant: 'subtitle', as: 'h3' },
                { variant: 'caption', as: 'h4' },
              ][level - 1]
              return <Text {...options} children={children} {...props} />
            },
            [NODE_PARAGRAPH]: (children, props) => {
              if (!!!children) return null

              if (!indented) {
                return <Text as={as ? 'span' : null} variant={variant} children={children} {...props} />
              }
              return (
                <Text as={as ? 'span' : null} variant={variant} {...props}>
                  <TextIndent>{children}</TextIndent>
                </Text>
              )
            },
            [NODE_LI]: (children, props) => (
              <Text variant={variant} as="li" rte={false} cropped={false} children={children} {...props} />
            ),
          },
          markResolvers: {
            [MARK_UNDERLINE]: (children) => (
              <Underline sx={{ display: 'inline-block', textIndent: 0 }} color={hardColor} children={children} />
            ),
            [MARK_LINK]: (children, props) => (
              <Underline
                as={Link}
                sx={{ display: 'inline-block', textIndent: 0 }}
                color={hardColor}
                variant={variant}
                link={props}
              >
                {children}
              </Underline>
            ),
          },
          defaultBlokResolver: (name, props) => (
            <Bloks
              blok={{ component: name, ...props }}
              sx={{ fontSize: 'inherit', textTransform: 'inherit' }}
              inverted={false}
            />
          ),
        })}
      {nodes}
    </div>
  ) : null
}

export default Rte
