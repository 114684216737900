export default {
  indent: {
    __default: {
      display: 'inline-block',
      textIndent: 'var(--text-indent, 1em)',

      'b, strong': {
        color: 'primary',
        fontWeight: '300!important',
      },
    },
  },
  icon: { size: { '--stroke-absolute': (t) => t.borderWidths[1] + 'px' } },
  layout: {
    section: {
      regular: {
        my: 3,
      },
      faq: {
        mt: 2,
        mb: 3,
        px: 1,

        borderTopWidth: 1,
        borderTopColor: 'text',
        borderTopStyle: 'solid',

        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',

        '>div': {
          gridColumn: ['1/-1', '2/-1', '3/-1'],

          '>button:first-of-type': {
            borderTop: 'none',
          },
        },
      },
      promo: {
        mb: 3,
        pt: 1,
        px: 1,

        '&[data-view="page"]': {
          mt: 'calc(var(--padding-m) + var(--header-height))',
          mb: 0,
        },
        '&[data-view="full"]': {
          mt: 3,
        },
        '&[data-view="dense"]': {
          mt: 'calc(var(--padding-m) + (var(--padding-x) * 2))',
        },

        borderTopWidth: 1,
        borderTopColor: 'text',
        borderTopStyle: 'solid',
      },
      dense: {
        borderTopWidth: 1,
        borderTopColor: 'text',
        borderTopStyle: 'solid',

        my: 2,

        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',

        '>div': {
          gridColumn: ['1/-1', null, '4/-1', '5/-1'],
        },
      },
      content: {
        borderTopWidth: 1,
        borderTopColor: 'text',
        borderTopStyle: 'solid',

        my: 2,
        pt: 1,
        px: 1,

        '>div': {
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridGap: 1,
        },
      },
      heading: {
        mt: 3,
        mb: 2,
      },
    },
  },
  button: {
    styles: {
      fontSize: 1,
      textTransform: 'uppercase',
      '--disabled-opacity': 0.35,

      '&::before': {
        borderRadius: '200px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'var(--border-color)',
      },
    },
    variants: {
      burger: {
        '--text': (t) => t.colors.burger_color,
        '--text-hover': (t) => t.colors.burger_color,
        '--bg': (t) => t.colors.burger_bg,
        '--bg-hover': (t) => t.colors.burger_bg,
        '--border-color': (t) => t.colors.burger_bg,

        '--b': (t) => t.colors.background,
      },
      primary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.primary,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.primary,

        '--b': (t) => t.colors.background,
      },
      secondary: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
      primaryFixed: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.primary,
        '--border-color': (t) => t.colors.primary,

        '--b': (t) => t.colors.background,
      },
      secondaryFixed: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
    },
    sizes: {
      sm: { p: '0.5em' },
      md: { p: ['1.5em 1.75em', null, '1.25em 1.75em'] },
      lg: { p: '0.65em' },
      slim: { p: ['1.05em 1.25em', null, '1.05em 1.25em'] },
      icons: {
        p: ['1.17em', null, '1.4em'],
        svg: {
          position: 'absolute',
          height: '75%',
          top: '50%',
          left: '50%',

          transform: 'translate(-50%,-50%)',
        },
      },
      iconsMd: {
        p: ['1.85em', null, '1.6em'],

        svg: {
          position: 'absolute',
          height: '75%',
          top: '50%',
          left: '50%',

          transform: 'translate(-50%,-50%)',
        },
      },
      __default: { variant: 'button.sizes.md' },
    },
    layout: {
      __default: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      round: {
        textAlign: 'center',
        borderRadius: '50%',
        size: 'var(--cta-size)',
      },
      withicon: {
        '>div': {
          display: 'inline-flex',
          alignItems: 'center',
        },
        'svg, .icon': {
          my: '-1em',
          width: 'unset',
          height: 'var(--icon-height, 1em)',

          mr: '0.55em',
        },
      },
    },
  },
  colorswitch: {
    variants: {
      bullet: {
        '--size': 'var(--icon-root-width, 0.65rem)',
        mt: '0.1rem',
      },
    },
    types: {
      inverted: {
        '--size': 'calc(var(--icon-root-width, 0.65rem) + (var(--stroke-x) * 2))',
        border: 'var(--stroke-x) solid var(--bg)',
        bg: 'var(--color)',
      },
    },
  },
}
