module.exports = {
  homeSlug: 'home',
  includeLinks: true,
  resolveLinks: 'story',
  resolveRelations: ['use-global.reference', 'faq_section.list'],
  locales: {
    locales: ['locales'],
    // tags: ['tags'],
  },
  langs: ['en'],
}
