import { useState, createContext, useContext } from 'react'
import { Grid, Flex, Box, useThemeUI } from 'theme-ui'
import { useSet } from '@react-hookz/web'
import { withHover } from '@monobits/core'
import { Section, Collapsible as Base } from '@monobits/components'
import SbEditable from 'storyblok-react'

import { Rte } from '../atoms'

const CollapseContext = createContext()

const Collapsible = withHover(
  ({ children, title, id, sx, hover, ...props }) => {
    const {
      bezier,
      transition: { duration },
    } = useThemeUI().theme?.motion.collapsible ?? {}
    const {
      set,
      auto,
      state: [state, setState],
    } = useContext(CollapseContext)

    const opened = auto ? state === id : !!set.has(id)

    return (
      <div
        sx={{
          variant: 'collapsible',
          '--speed': `${duration}s`,
          '--ease': bezier,
          ...sx,
        }}
        data-handle={id}
        data-selected={!!hover || opened}
        aria-expanded={opened}
        data-scroll
        data-fade
        {...props}
      >
        <Grid
          as="button"
          sx={{ variant: 'collapsible.head' }}
          onClick={() => {
            if (auto) {
              return setState(opened ? '' : id)
            }

            return opened ? set.delete(id) : set.add(id)
          }}
        >
          <Box as="h3" sx={{ variant: 'collapsible.text' }}>
            {title}
          </Box>
          <Flex sx={{ variant: 'collapsible.icon' }}>
            <Flex as="aside" />
          </Flex>
        </Grid>
        <Base onTrigger={!opened}>{children}</Base>
      </div>
    )
  },
  { mouse: true }
)

const Collapsibles = ({ blok, children, auto = true, sanitize, ...props }) => {
  const { items, _uid } = blok ?? {}
  const set = useSet([])
  const state = useState('')

  return (
    <SbEditable content={blok} key={_uid}>
      <CollapseContext.Provider value={{ set, state, auto }}>
        <Section variant="dense" {...sanitize(props)}>
          <div>
            {items.map((item, i) => (
              <SbEditable content={item} key={item._uid}>
                <Collapsible title={item.title} id={item._uid} key={item._uid}>
                  <Rte content={item.rte} sx={{ variant: 'collapsible.rte' }} variant="list" />
                </Collapsible>
              </SbEditable>
            ))}
          </div>
        </Section>
      </CollapseContext.Provider>
    </SbEditable>
  )
}

export default Collapsibles
