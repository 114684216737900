export default {
  __default: {
    pt: [0, null, 1],
    mt: 'var(--header-height, 60px)',
    minHeight: 'calc((var(--vh, 1vh) * 100) - var(--header-height, 60px))',

    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    '--pt': ['0', '8vh', '18vh'],

    height: '100%',
    position: 'relative',
    pt: 'var(--pt)',
    flexGrow: 1,
  },
  body: {
    pt: 1,
    px: 1,
    width: '100%',
    height: '100%',

    // position: 'relative',

    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: 1,

    alignContent: 'start',

    '>[data-cropped]': {
      '&:nth-of-type(1)': { gridColumn: ['1/9', null, '1/5'] },
      '&:nth-of-type(2)': { gridColumn: ['9/13', null, '9/11'] },
      '&:nth-of-type(3)': { gridColumn: ['9/13', null, '11/13'] },
    },
  },
  stroke: {
    position: 'absolute',
    top: 'var(--pt)',
    left: 0,

    width: '100%',
    height: 'var(--stroke-x)',
    bg: 'text',

    transform: 'scale(1.001)',
    transformOrigin: 'left',
    willChange: 'transform',
  },
  avatars: {
    cursor: 'pointer',
    position: 'absolute',
    top: ['3%', null, 0],
    bottom: 0,
    left: '50%',

    width: ['100%', null, '36%'],
    '@media (hover: none) and (min-width: 1024px)': {
      width: '60%',
    },
    height: '100%',
    maxHeight: '100%',

    display: 'grid',
    justifyItems: 'center',
    alignContent: 'center',

    transform: 'translateX(-50%)',

    '[data-img]': {
      m: [null, null, '-4rem'],
      minHeight: 0,
      width: '100%',
      gridColumn: '1/-1',
      gridRow: '1/2',
    },
  },
  image: {
    pointerEvents: 'none',
  },
  foot: {
    py: 1,
    px: 1,
    pt: [0, null, 'calc(var(--padding-x) * 2)'],

    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: 1,

    alignItems: 'end',

    '>[data-cropped]': {
      gridColumn: '1/7',
    },

    nav: {
      gridColumn: ['1/-1', null, '9/-1'],

      width: '100%',
      position: 'relative',
      whiteSpace: 'nowrap',

      display: 'flex',
      justifyContent: 'flex-end',

      '>div': {
        flexGrow: '1',
        display: ['flex', 'grid'],
        gridTemplateColumns: [null, 'repeat(2, 1fr)'],
        gap: [0, 1],
        flexDirection: 'column-reverse',
      },
    },
  },
}
