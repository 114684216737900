import { useRef } from 'react'
import { useIsomorphicLayoutEffect as useLayoutEffect, useConditionalEffect } from '@react-hookz/web'
import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Observer } from 'gsap/Observer'
import { debounce, omit } from 'lodash'

import { useSmoother } from '.'
import { CONFIGS } from '../__consts'
import * as set from './updateEvents'

gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

const forwardEvent = (key = 'onUpdate', values = {}, props = []) => key in values && values?.[key](...props)

const useScrollSmoother = (enabled = true, { watch = [], predicates = [] } = {}) => {
  const { ids, get, configs = {}, onMount, onUnMount } = useSmoother() ?? {}

  const expose = { set, ids, get }
  const cfg = omit(configs, ['ignoreMobile'])
  const isTouch = !!Observer?.isTouch

  const props = {
    ...CONFIGS,
    ...cfg,
    onUpdate: (self) => {
      set.isScrolling(true)
      forwardEvent('onUpdate', cfg, [self?.scrollTrigger ?? {}, expose])
    },
    onStop: () => {
      set.isScrolling(false)
      forwardEvent('onStop', cfg, [expose])
    },
  }

  const strict_enabled = !!!configs?.ignoreMobile ? !!enabled : !!enabled && !!!isTouch

  let instance = useRef(null)

  const updateConfig = JSON.stringify({ ids, enabled, ...configs })

  useConditionalEffect(
    () => {
      if (!!strict_enabled) {
        instance.current = ScrollSmoother.create({ ...ids, ...props })
        set.onStartUpdate(instance.current?.scrollTrigger ?? {}, { ids, get, is_smooth: true, ...(props ?? {}) })

        onMount(strict_enabled)

        // access the instance without state (usefull for page transitions)
        window._gsap_scrollsmoother = instance.current
      } else {
        !!props?.ignoreMobileResize && ScrollTrigger.config({ ignoreMobileResize: props?.ignoreMobileResize ?? true })
        // !!props?.normalizeScroll && ScrollTrigger.normalizeScroll(props?.normalizeScroll ?? true)

        instance.current = ScrollTrigger.create({
          id: 'root',
          trigger: ids?.content,
          start: 'top top',
          end: 'bottom bottom',
          onUpdate: (self) => {
            forwardEvent('onUpdate', configs, [self, expose])
          },
        })
        set.onStartUpdate(instance.current ?? {}, { ids, get, is_smooth: false, ...(props ?? {}) })

        window._gsap_scrollsmoother = null
      }

      const body = get('content')

      const resizeObserver = new ResizeObserver(debounce(() => ScrollTrigger?.refresh(), 400))

      resizeObserver.observe(body)

      return () => {
        resizeObserver.unobserve(body)
        instance.current?.kill()
        onUnMount(strict_enabled)
      }
    },
    [...(watch ? watch : []), updateConfig],
    [...predicates],
    undefined,
    useLayoutEffect
  )
}

export const getSmoother = () => window?._gsap_scrollsmoother ?? ScrollSmoother?.get()
export const getScrollTrigger = () => {
  const smoother = getSmoother()
  if (smoother == null) return ScrollTrigger.getById('root')
  return smoother?.scrollTrigger
}
export const refresh = () =>
  window?._gsap_scrollsmoother != null ? (window?._gsap_scrollsmoother ?? {})?.refresh() : ScrollTrigger?.refresh()
export const refreshAll = () => ScrollTrigger?.refresh()

export default useScrollSmoother

// Codepens
// https://codepen.io/GreenSock/pen/jOYNQWp?editors=1010
// https://codepen.io/GreenSock/pen/xxXadQJ
// https://codepen.io/vanholtzco/details/abEOead

// Useful threads
// https://github.com/gatsbyjs/gatsby/issues/7310
// https://github.com/reach/router/issues/63#issuecomment-428050999
