import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { TextPlugin } from 'gsap/TextPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Observer } from 'gsap/Observer'

gsap.registerPlugin(TextPlugin, ScrollToPlugin, Observer, CustomEase)

CustomEase.create('cycle', 'M0,0 C0,0 0.124,0.634 0.65,0.852 0.973,0.985 1,1 1,1')
CustomEase.create('progress', 'M0,0 C0.104,0.204 0.054,0.624 1,1')

gsap.registerEffect({
  name: 'userSelect',
  effect: (targets, config = false) => {
    if (config.value !== true) {
      return gsap.set(targets, { '--user-select': config.value })
    } else {
      return gsap.set(targets, { clearProps: '--user-select' })
    }
  },
  extendTimeline: true,
})

// gsap.registerEffect({
//   name: 'fade',
//   effect: (targets, config) => {
//     return gsap.fromTo(
//       targets,
//       {
//         scale: 0.98,
//         rotate: 2,
//         autoAlpha: 0,
//         ...config.from,
//       },
//       {
//         scale: 1,
//         rotate: 0,
//         autoAlpha: 1,
//         ...config.to,
//       }
//     )
//   },
//   defaults: {
//     to: {
//       duration: 0.2,
//       ease: 'expoOut',
//       paused: true,
//     },
//   },
//   extendTimeline: true,
// })
