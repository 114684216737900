import loadable from '@loadable/component'

export const ReactP5Wrapper = loadable(() => import('react-p5-wrapper'), {
  resolveComponent: (components) => components.ReactP5Wrapper,
  ssr: false,
})

// export const ReactP5Wrapper = loadable(async () => {
//   const { ReactP5Wrapper } = await import('react-p5-wrapper')
//   return ReactP5Wrapper
// })
