import { useMemo } from 'react'
import { useMediaQuery } from '@react-hookz/web'
import { Section } from '@monobits/components'
import { Image as StoryblokImage } from '@boiler/image'
import { Lazy } from '@boiler/intersection-observer'
import { multiLink } from '@boiler/utils'
import { times } from 'lodash'

import { Button } from '../../atoms'
import Row from './Row'

const Showcase = ({ assets, rows, uid, alignment = 'left', link = {}, sx, ...props }) => {
  const b = useMediaQuery('(min-width: 53em)', true)
  const c = useMediaQuery('(min-width: 62em)', true)
  const d = useMediaQuery('(min-width: 72em)', true)
  const e = useMediaQuery('(min-width: 92em)', true)
  const f = useMediaQuery('(min-width: 122em)', true)

  let breakpoint = 0
  if (b) breakpoint = 1
  if (c) breakpoint = 2
  if (d) breakpoint = 3
  if (e) breakpoint = 4
  if (f) breakpoint = 5

  const spans = [
    [3, 1],
    [4, 1],
    [5, 1],
    [6, 2],
    [7, 2],
    [8, 2],
  ]

  const filledRow = (i) => {
    const size = 10
    const row = rows?.[i] ?? []
    return row?.length < size ? times(size - row?.length, (i) => row?.[i % row?.length]) : row
  }

  const medias_ready = !!(b != null)

  const makeCells = (i) => {
    const paths = filledRow(i)

    return medias_ready
      ? paths.map((img, i) => (
          <Lazy key={uid + img.id + i} inview={{ once: true }} eager={false}>
            {({ ref, inview }) => (
              <StoryblokImage
                ref={ref}
                data={img}
                sx={{ variant: 'showcase.cell' }}
                inview={inview}
                object={{ fit: 'contain', position: 'center center' }}
                fitted={true}
              />
            )}
          </Lazy>
        ))
      : null
  }

  const first = useMemo(() => makeCells(0), [rows?.[0], medias_ready]) // eslint-disable-line
  const second = useMemo(() => makeCells(1), [rows?.[1], medias_ready]) // eslint-disable-line

  return (
    <Section sx={{ variant: 'showcase', ...sx }} {...props}>
      {'text' in link && (
        <Button
          sx={{ variant: 'showcase.button' }}
          {...multiLink(link?.link)}
          data-alignment={alignment}
          layout="round"
        >
          {link?.text}
        </Button>
      )}
      <div sx={{ variant: 'showcase.container' }} data-ready={!!medias_ready}>
        <Row children={first} inverted={false} spans={spans[breakpoint]} />
        <Row children={second} inverted={true} spans={spans[breakpoint]} />
      </div>
    </Section>
  )
}

export default Showcase
