import { Link as GatsbyLink } from 'gatsby'
import { forwardRef } from 'react'
// import normalizeUrl from 'normalize-url'
import { get } from 'lodash'

const Link = forwardRef((props, ref) => <GatsbyLink ref={ref} activeClassName="_active" {...props} />)

const types = {
  story: {
    as: Link,
    attr: 'to',
    path: 'story.full_slug',
    external: false,
    extend: {},
  },
  url: {
    as: 'a',
    attr: 'href',
    path: 'url',
    external: true,
    extend: {
      target: '_blank',
      rel: 'noreferrer noopener',
    },
  },
}

const removeTrailingSlash = (url) => (url?.length !== 1 ? url?.replace(/\/$/, '') : url)

export const multiLink = (link = {}, params = false, skip = false) => {
  if (!!skip) return {}

  const { as, attr, path, external, extend } = types?.[link?.linktype] ?? {}
  const _url = get(link, path) || get(link, 'href')
  const url = removeTrailingSlash(_url) === 'home' ? '' : _url

  let output
  // if (external) output = normalizeUrl(url)
  if (external) output = url
  if (!external && !params) output = '/' + removeTrailingSlash(url)
  if (!external && params) output = '/' + removeTrailingSlash(url) + params

  return { as, [attr]: output, ...extend }
}
