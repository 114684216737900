import { useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useConditionalEffect } from '@react-hookz/web'
import { getSmoother } from '@boiler/smoother'

import { useData } from '../../hooks'
import { avatarsCycleState } from '../../states'

const smoothPaused = (bool) => getSmoother()?.paused(bool)

const usePrepare = () => {
  const [locked, setLocked] = useState(true)
  let completed = useRef(false)

  const setCycle = useSetRecoilState(avatarsCycleState)
  const setRendered = useData()?.rendered?.[1] ?? []

  useConditionalEffect(
    () => {
      smoothPaused(true)
    },
    undefined,
    [!!locked]
  )

  const onComplete = () => {
    smoothPaused(false)
    setLocked(false)
    setTimeout(() => setRendered(true), 100)
  }
  const onStart = () => (completed.current = true)
  const cycle = () => setCycle((prev) => prev + 1)

  return [{ onComplete, onStart, locked }, [!completed.current], cycle]
}

export default usePrepare
