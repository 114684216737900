export default {
  __default: {
    // mt: 3,
    minHeight: 'calc((var(--vh-x, 1vh) * 100) - var(--header-height))',

    justifyContent: 'space-between',
  },
  head: {
    px: 1,
  },
  foot: {
    p: 1,
    width: '100%',
    height: '100%',

    borderTopWidth: 'var(--stroke-x)',
    borderTopStyle: 'solid',
    borderTopColor: 'text',

    display: 'flex',
    gap: 1,

    flexDirection: 'row',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
}
