import { inset } from '@monobits/core'

export default {
  position: 'relative',
  width: '100%',

  img: {
    size: '100%',
    position: 'absolute',
    userSelect: 'none',
    ...inset(0),
  },

  '>[data-image]': {
    objectFit: 'var(--object-fit)',
    objectPosition: 'var(--object-position)',
    opacity: 0,
  },

  '&[data-with-transition=true]>[data-image],&[data-with-transition=true]>[data-placeholder]': {
    transition: 'var(--transition, none)',
  },
  '&[data-with-transition=true]>[data-placeholder]': {
    opacity: 0,
    transition: 'opacity 0.15s ease',
  },

  '>[data-placeholder]': {
    objectFit: 'cover',
    objectPosition: 'var(--object-position)',
    filter: 'blur(10px)',
    transform: 'scale(1.075)',

    '&[data-loaded=true]': {
      opacity: 1,
    },
  },
  '&[data-loaded=true]': {
    '>[data-image]': { opacity: 1 },
  },
  '&[data-inset=true]': {
    position: 'absolute',
    ...inset(0),
  },
}
