import { Section } from '@monobits/components'
import { MapBloks } from '../bloks'
import { Rte } from '../atoms'

const Lookbook = ({ blok, settings, ...props }) => {
  const { body, long_title, caption } = blok ?? {}

  const content = body && <MapBloks items={body} {...props} />

  return (
    <Section data-view="page" variant="promo" sx={{ variant: 'promo' }}>
      <aside sx={{ variant: 'promo.head' }}>
        <Rte data-scope="caption" content={long_title} variant="text" />
        <Rte data-scope="description" content={caption} variant="text" />
      </aside>

      {content}
    </Section>
  )
}

export default Lookbook
