import { gsap } from 'gsap'

const $ = (obj) => obj?.current
const text = (obj) => $(obj)?.childNodes?.[0]

export const setup = () =>
  gsap
    .timeline()
    .set('#content-holder,footer', { opacity: 0 })
    // .set(['[data-anchor="hero"]'], { opacity: 0 })
    .set('[data-gsap="logo"],[data-gsap="hero-buttons"],[data-gsap="hero-stagger"],[data-gsap="avatars"]', {
      opacity: 0,
      y: '2rem',
    })
    .set('[data-gsap="sticker"],[data-gsap="burger"]', { opacity: 0, y: '-3rem' })
    .set('[data-gsap="hero-stroke"]', { scaleX: 0.001 })

export const progress = (ref, percent) =>
  gsap
    .timeline({ delay: 0.7 })
    .set($(ref), { background: 'none' })
    .fromTo('[data-gsap="logo"]', { y: '2rem' }, { y: 0, duration: 1, ease: 'expo.out' }, 'init')
    .fromTo('[data-gsap="logo"]', { opacity: 0 }, { opacity: 1, duration: 2.5, ease: 'expo.out' }, 'init')
    .fromTo(text(ref), { y: '2rem' }, { y: 0, duration: 1, ease: 'expo.out' }, 'init+=0.25')
    .fromTo(text(ref), { opacity: 0 }, { opacity: 1, duration: 2.5, ease: 'expo.out' }, 'init+=0.25')
    .fromTo(
      $(percent),
      { value: 0 },
      {
        value: 100,
        duration: 2,
        ease: 'progress',
        onUpdate: () => gsap.set(text(ref), { text: '%' + ~~$(percent).value }),
      },
      'init+=0.5'
    )

export const layout = (ref) =>
  gsap
    .timeline()
    .to('[data-gsap="sticker"],[data-gsap="burger"]', { y: 0, opacity: 1, duration: 1 }, 'header-=0.5')
    .to(text(ref), { opacity: 0, duration: 1 }, '<0.5')
    .to('#content-holder,footer', { opacity: 1, duration: 0.8 })
    .to(
      '[data-gsap="hero-stagger"]',
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: { amount: 0.5 },
      },
      '<0.35'
    )
    .add('stroke')
    .to('[data-gsap="hero-buttons"]', { opacity: 1, y: 0, duration: 0.5 }, '>-0.25')
    .to('[data-gsap="hero-stroke"]', { scaleX: 1, duration: 2, ease: 'expo.out' }, 'stroke>-=1.35')
    .to('[data-gsap="avatars"]', { opacity: 1, y: 0, duration: 1.5, ease: 'expo.out' }, 'stroke>-=0.5')
