import Placeholder from './Placeholder'
import GlobalComponent from './GlobalComponent'

import * as X from '../components'
import MultilineLink from '../atoms/MultilineLink'
import Links from '../atoms/Links'

const Components = {
  global: GlobalComponent,
  'use-global': GlobalComponent,
  'nav-item': X.NavItem,
  'text-item': X.TextItem,
  'copyrights-mention': X.Copyrights,
  'email-link': X.Obfuscate.Email,
  'phone-link': X.Obfuscate.Phone,
  'multiline-link': MultilineLink,
  'social-link': X.SocialLink,
  'content-group': X.ContentGroup,
  asset: X.Asset,
  'asset-plus': X.Asset,
  faq_section: X.FaqSection,
  heading: X.Heading,
  navigation: Links,
  collapsibles: X.Collapsibles,
  rte: X.RteItem,
  hero: X.Hero,
  showcase: X.Showcase,
  attributes: X.Slider,
  image: X.Image,
  video: X.Video,
  lottie: X.Lottie,
  promo: X.Promo,
  'promo-gallery': X.PromoGallery,
  gallery: X.Gallery,
  'gallery-block': X.GalleryBlock,
}

const DynamicComponent = ({ blok, source, ...props }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} {...props} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
