import { useSet } from '@react-hookz/web'
import { localize, handleize } from '@boiler/utils'

const useFilters = ({ entries, lang, locales: { tags: locale }, source: { slug } }) => {
  const set = useSet([])

  const edges = entries.edges.filter(({ node }) => node.default_full_slug.includes(slug) && node.lang === lang)
  const all_selectors = edges.reduce((acc, { node }) => acc.concat(node.tag_list.map((i) => handleize(i))), [])
  const selectors = new Set(['all', ...Object.values(all_selectors)])

  const localized = (key) => localize(key, locale)

  const filters = Array.from(selectors)
    .sort((a, b) => localized(a).localeCompare(localized(b)))
    .reduce((acc, item) => {
      item === 'all' ? acc.unshift(item) : acc.push(item)
      return acc
    }, [])
    .reduce((acc, item) => ({ ...acc, [item]: localized(item) }), {})

  return {
    set,
    filters: Object.entries(filters),
    items: set.size ? edges.filter(({ node }) => node.tag_list.some((item) => set.has(handleize(item)))) : edges,
    dispatch: (node) => {
      const toggle = set.has(node) ? set.delete : set.add
      return node === 'all' ? set.clear() : toggle(node)
    },
  }
}

export default useFilters
