import { Box } from 'theme-ui'
import { Section } from '@monobits/components'
import SbEditable from 'storyblok-react'

import { Rte } from '../../atoms'
import { MapBloks } from '../../bloks'
import { useData } from '../../hooks'

const PromoDense = ({ blok, ...props }) => {
  const { breakpoint: bk } = useData()
  const { title, caption, asset, gallery, _uid } = blok ?? {}

  const media = <MapBloks placeholder={true} persistent={true} items={asset} id={_uid} {...props} />
  const medias = <MapBloks placeholder={true} persistent={true} items={gallery} id={_uid} {...props} />

  const breakpoint = bk > 1 ? 'desktop' : 'mobile'

  return (
    <SbEditable content={blok} key={_uid}>
      <Section data-view="dense" data-break={breakpoint} variant="promo" sx={{ variant: 'promo' }}>
        <aside sx={{ variant: 'promo.head' }}>
          <Rte data-scope="caption" content={title} variant="text" />

          {breakpoint === 'mobile' && <Rte data-scope="description" content={caption} variant="text" />}

          {breakpoint === 'desktop' && (
            <Box data-scope="asset" sx={{ variant: 'promo.asset' }}>
              {media}
            </Box>
          )}

          {breakpoint === 'mobile' && (
            <Box data-scope="gallery" sx={{ variant: 'promo.gallery' }}>
              {medias}
            </Box>
          )}

          {breakpoint === 'desktop' && (
            <>
              <Rte data-scope="description" content={caption} variant="text" />
              <Box data-scope="gallery" sx={{ variant: 'promo.gallery' }}>
                {medias}
              </Box>
            </>
          )}
        </aside>
      </Section>
    </SbEditable>
  )
}

export default PromoDense
