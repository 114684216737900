import { useEffect } from 'react'
import { useSafeState } from '@react-hookz/web'
import { useISO, getStory, getSettings, getLocales, returnSettings, returnLocales } from '@boiler/utils'
import StoryblokClient from 'storyblok-js-client'

import { omit } from 'lodash'
import sbConfig from '../../config.storyblok'

const TOKEN = process.env.GATSBY_STORYBLOK_TOKEN
const SOURCES = sbConfig?.locales
const config = omit(sbConfig, ['langs', 'homeSlug', 'includeLinks', 'locales'])

const Storyblok = new StoryblokClient({
  accessToken: TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
})

const useStoryblok = (pageContext, location) => {
  const { story: originalStory, lang } = pageContext ?? {}
  const LOCALIZED = !!(sbConfig.langs.length - 1)
  const ISO = useISO(pageContext, location, 'internal', LOCALIZED)

  let [story, setStory] = useSafeState(originalStory)
  let [settings, setSettings] = useSafeState(pageContext?.config?.settings)
  let [locales, setLocales] = useSafeState(pageContext?.datasources)

  if (story && typeof story.content === 'string') story.content = JSON.parse(story.content)

  function initEventListeners() {
    const { StoryblokBridge } = window

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge(config)

      storyblokInstance.on(['input'], (event) => {
        if (story == null || event.story._uid === story._uid) {
          setStory(event.story)
        }
      })

      storyblokInstance.on(['enterEditmode'], async (event) => {
        const parameters = {
          lang: LOCALIZED ? lang : null,
          ISO: LOCALIZED ? ISO : 'default',
          pageContext,
          Storyblok,
          config,
          event,
        }

        await getStory({ ...parameters, setter: setStory })
        await getSettings({ ...parameters, setter: setSettings })
        await getLocales({ ...parameters, setter: setLocales, SOURCES })
      })
    }
  }

  function addBridge(callback) {
    const existingScript = document.getElementById('storyblokBridge')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`
      script.id = 'storyblokBridge'
      document.body.appendChild(script)
      script.onload = () => callback()
    } else {
      setTimeout(() => callback(), 1000)
    }
  }

  useEffect(() => {
    if (location.search.includes('_storyblok')) {
      addBridge(initEventListeners)
    } else if (pageContext == null || !!!(pageContext ?? {})?.config) {
      // navigate(ISO !== 'default' && ISO != null ? '/fr/page-not-found' : '/page-not-found', { replace: true })

      // Need an actual redirect to get data to the layout
      window.location.replace('/page-not-found')
    }
  }, []) // eslint-disable-line

  return {
    story,
    locales: returnLocales(locales, ISO),
    settings: returnSettings(settings, ISO),
  }
}

export default useStoryblok
