export default {
  __default: {
    px: 1,
    py: 1,

    display: 'grid',
    gridTemplateColumns: '1fr var(--icon-size)',

    rowGap: 1,

    '--icon-size': '2.5rem',

    '[data-img],[data-ready]': {
      borderRadius: 'var(--padding-x)',
      transform: 'translateZ(0)',
      overflow: 'hidden',
    },
  },

  group: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 3fr'],
    columnGap: 1,
    rowGap: 1,

    pt: 1,
    borderTop: 'var(--stroke-x) solid currentColor',
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,

    gridColumn: '1/-1',
  },

  head: {
    display: 'grid',
    gridTemplateColumns: '1fr var(--icon-size)',
    gap: 1,

    gridColumn: '1/-1',

    h3: {
      '--mt': ['1rem', 'var(--padding-x)'],
      mt: 'var(--mt)',
      mb: 'calc(var(--padding-m) - var(--mt))',
    },
  },

  moves: {
    display: 'flex',
    gap: '6rem',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 1,

    '[data-scrollable=false] &': {
      display: 'none',
    },
  },

  close: {
    size: 'var(--icon-size)',
    borderRadius: '50%',

    bg: 'light',
    color: 'dark',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&[data-sticked=true]': {
      position: 'sticky',
      top: 1,
      left: 0,

      zIndex: 1,
    },

    svg: {
      width: 'unset',
    },
  },
}
