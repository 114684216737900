import { Image as StoryblokImage } from '@boiler/image'
import { Lazy } from '@boiler/intersection-observer'

const Image = ({
  blok,
  eager = false,
  fit,
  __root,
  ratio,
  position,
  lazyload,
  fitted,
  sanitize = (p) => p,
  ...props
}) => {
  const { image } = blok ?? {}

  return (
    <Lazy inview={{ once: true }} options={__root != null ? { root: __root } : {}} eager={eager}>
      {({ ref, inview }) => (
        <StoryblokImage
          ref={ref}
          data={image}
          inview={inview}
          object={{ fit, position }}
          ratio={!!ratio ? ratio : null}
          fitted={fitted ?? !!ratio}
          placeholder
          {...sanitize(props)}
        />
      )}
    </Lazy>
  )
}

export default Image
