import { useRef } from 'react'
import { useConditionalEffect } from '@react-hookz/web'
import { Heading } from '@monobits/components'
import { gsap } from 'gsap'
import ScrollLock from 'react-scrolllock'

import usePrepare from './usePrepare'
import { setup, progress, layout } from './timelines'

const Intro = ({ ...props }) => {
  const [events, predicates, cycle] = usePrepare()

  let once = useRef(false)
  let container = useRef(null)
  let percent = useRef({ value: 0 })

  const timeline = () => {
    const refs = [container, percent]
    return gsap
      .timeline({ paused: true, ...events })
      .add(setup(...refs))
      .add('init')
      .add(progress(...refs))
      .add('header')
      .add(layout(...refs))
      .call(cycle, null, '>-=150%')
  }

  useConditionalEffect(
    () => {
      timeline().play()
      once.current = true
    },
    undefined,
    [...predicates, !!!once.current]
  )

  return (
    <section ref={container} sx={{ variant: 'intro' }} {...props}>
      <ScrollLock isActive={events.locked} />
      <Heading
        as="span"
        variant="percent"
        aria-label="loading percentage"
        sx={{ opacity: 0, transform: 'translateY(2rem)' }}
      >
        %0
      </Heading>
    </section>
  )
}

export default Intro
