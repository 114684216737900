import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useConditionalEffect, useUpdateEffect, useDebouncedState, useWindowSize } from '@react-hookz/web'
import { willChangeEvents } from '@monobits/gsap'
import { Heading } from '@monobits/components'
import { gsap } from 'gsap'
import ScrollLock from 'react-scrolllock'

import { Icons } from '../../atoms'
import { MapBloks } from '../../bloks'
import { useTween } from '../../hooks'
import { faqSelectedState, faqDataState, faqDataAll, scrollPausedState, scrollActiveState } from '../../states'

const FaqPanel = ({ watch = [] }) => {
  const [_all, setAll] = useRecoilState(faqDataAll)
  const [selected, setSelected] = useRecoilState(faqSelectedState)
  const [data, setData] = useRecoilState(faqDataState)
  const setScrollPaused = useSetRecoilState(scrollPausedState)
  const scrollActive = useRecoilValue(scrollActiveState)

  const resize = useWindowSize(useDebouncedState)

  const [tween, element] = useTween(
    ({ node }) => {
      const step = (window?.innerWidth ?? 0) / 6
      const maxWidth = step * 2 - 10
      const shiftAmount = node?.clientWidth * 1

      const x = -gsap.utils.clamp(0, maxWidth, shiftAmount)

      return gsap
        .timeline({
          paused: true,
          defaults: {
            duration: 0.8,
            ease: 'expo.inOut',
            ...willChangeEvents(node),
          },
          onStart: () => (node.scrollTop = 0),
          onComplete: () => {
            setScrollPaused(true)
          },
          onReverseComplete: () => {
            setScrollPaused(false)
          },
        })
        .to(node, { visibility: 'visible', duration: 0 })
        .add('start')
        .to(node, { '--clip-path': '0%' }, 'start')
        .to('#main', { x }, 'start')
    },
    [resize?.width]
  )

  const refresh_key = 'faq-overlay' + data?.uuid ?? ''

  const scrollToTop = () => gsap.to(element.current, { scrollTo: { y: 0, autoKill: true }, duration: 0.4 })

  useUpdateEffect(() => {
    gsap.set(element.current, { clearProps: 'all' })
    gsap.set('#main', { clearProps: 'all' })
    setSelected('')
  }, [resize?.width])

  useConditionalEffect(
    () => {
      element.current.scrollTop = 0
      element.current.setAttribute('data-scrollable', element.current?.scrollHeight > element.current?.clientHeight)
      return () => element.current.setAttribute('data-scrollable', false)
    },
    [refresh_key],
    [element.current != null, !!selected],
    undefined,
    useUpdateEffect
  )

  useConditionalEffect(
    () => {
      tween?.[!!selected ? 'play' : 'reverse']()
    },
    [selected],
    [tween],
    undefined,
    useUpdateEffect
  )

  useUpdateEffect(() => {
    setSelected('')
  }, watch)

  const { data: all, current_index } = _all ?? 0
  const action = {
    next: (current_index + 1) % all?.length,
    prev: (current_index + all?.length - 1) % all?.length,
  }

  const handleMoves = (dir = 'next') => {
    const new_data = all?.[action?.[dir]]

    setAll((prev) => ({ ...prev, current_index: action?.[dir] }))
    setSelected(new_data?.slug)
    setData(new_data)
  }

  const actions = (
    <nav sx={{ variant: 'faq.moves' }}>
      <Heading as="button" variant="caption" onClick={() => handleMoves('prev')}>
        Previous
      </Heading>
      <Heading as="button" variant="caption" onClick={() => handleMoves('next')}>
        Next
      </Heading>
    </nav>
  )

  const footer = (
    <nav sx={{ variant: 'faq.footer' }}>
      {actions}
      <button sx={{ variant: 'faq.close' }} onClick={scrollToTop}>
        <Icons name="arrow-up" sx={{ height: '50%' }} />
      </button>
    </nav>
  )

  const overlays = (
    <div key={refresh_key} sx={{ variant: 'faq' }} data-item={data?.slug}>
      {actions}

      <button sx={{ variant: 'faq.close' }} data-sticked="true" onClick={() => setSelected('')}>
        <Icons name="cross" sx={{ height: '40%' }} />
      </button>

      <div sx={{ variant: 'faq.head' }}>
        <Heading variant="item" as="h3">
          {data?.content?.title ?? ''}
        </Heading>
      </div>

      <div sx={{ variant: 'faq.body' }}>
        <MapBloks
          id="is-root"
          items={data?.content?.body ?? [{}]}
          childProps={{
            'content-group': {
              video: {
                playing: !!selected,
                autoplay: true,
                __root: '#panel-root',
              },
            },
            lottie: { autoplay: true, loop: true },
            rte: { hardColor: 'background' },
          }}
        />
        {footer}
      </div>
    </div>
  )

  return (
    <section
      ref={element.ref}
      id="panel-root"
      data-expanded={!!selected}
      data-scrollable={false}
      sx={{ variant: 'panel' }}
    >
      <ScrollLock isActive={!!!scrollActive} />
      {overlays}
    </section>
  )
}

export default FaqPanel
