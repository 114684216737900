import { MapBloks } from '../bloks'

const Page = ({ blok, settings, ...props }) => {
  const { body } = blok ?? {}

  const content = body && <MapBloks items={body} {...props} />

  return <>{content}</>
}

export default Page
