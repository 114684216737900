import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { smootherStyles } from '@boiler/smoother'
import { inset } from '@monobits/core'
import { omit } from 'lodash'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import transitions from './transitions'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

import './gsap'

export default merge(omit(preset, ['colors']), {
  config,
  colors,
  transitions,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...smootherStyles,
      ...rootFontStyles,
      ...rootScaleStyles,

      '--gradient': 'linear-gradient(38.83deg, #F25A1D 13.8%, #F9EAE4 83.82%)',

      '--icon-root-width': '0.6rem',
      '--icon-height': '1.2em',
      '--icon-width': '1.25em',

      '--ui-duration': '0.45s',
      '--ui-ease': 'ease',

      '@media (hover: none)': { '--p-offset': '0rem' },
      '@media (hover: hover)': { '--p-offset': 0 },

      body: { bg: 'background', '.c-scrollbar': { zIndex: 'scrollbar' } },
      '&.has-scroll-smooth body': {
        position: 'fixed',
        ...inset(0),
      },
    },
  },

  motion: {
    collapsible: {
      transition: {
        duration: 0.4,
        ease: 'power1',
      },
      bezier: 'ease',
    },
  },

  ...fonts,
  ...scales,
  ...overrides,
  ...variants,
})
