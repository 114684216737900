// import { cloudUrl } from '@boiler/cloudinary'

const frameRate = 40 // default to 60
const multiplier = 0.11
const randomized = false
const bleed = 0.5

/**
 * TO-DO:
 * - QA responsive & SSR
 */

const sketch = (p5) => {
  let images = []
  let index = 0

  p5.setup = () => {
    p5.createCanvas(window.innerWidth, window.innerHeight)
    p5.frameRate(frameRate)
    p5.noLoop()
  }

  p5.draw = () => {
    if (!!images.length) {
      index = randomized ? randomIndex(images) : (index + 1) % images.length

      let growth = Math.max(p5.width, p5.height) * multiplier
      let imgX = 2 * growth
      let imgY = 3 * growth

      let x = axis(imgX, p5.width)
      let y = axis(imgY, p5.height)

      p5.imageMode(p5.CENTER)
      p5.translate(p5.width / 2, p5.height / 2)

      p5.rotate(p5.random(0, 360))
      p5.image(images[index], x, y, imgX, imgY)
    }
  }

  p5.updateWithProps = ({ active, paths }) => {
    if (!!images.length) active ?? !!active ? start() : stop()
    if (paths != null && !!!images.length) {
      paths.map((path, i) => (images[i] = p5.loadImage(path)))
    }
  }

  p5.windowResized = () => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight)
    stop()
  }

  function axis(value, max) {
    return p5.random(-(value * bleed), max - value * bleed)
  }

  function randomIndex(arr) {
    return Math.round(p5.random(0, arr.length - 1))
  }

  function start() {
    p5.loop()
  }

  function stop() {
    setTimeout(() => p5.clear(), 100)
    p5.noLoop()
  }
}

export default sketch
