import { inset } from '@monobits/core'

const borders = {
  borderWidth: 1,
  borderColor: 'text',
  borderStyle: 'solid',

  borderRadius: 'var(--radius)',
  overflow: 'hidden',
}

export default {
  __default: {
    '--radius': (t) => t.space[1],

    '--width': '100vw / 1.5',
    '@media (min-width: 43em)': { '--width': '100vw / 2' },
    '@media (min-width: 62em)': { '--width': '100vw / 3' },
    '@media (min-width: 78em)': { '--width': '100vw / 4.25' },
    '@media (min-width: 112em)': { '--width': '100vw / 4.75' },

    overflow: 'hidden',
  },
  caption: {
    gridColumn: ['1/-1', '1/3'],
    mt: '2px',
  },
  content: {
    gridColumn: ['1/-1', '3/-1'],
    position: 'relative',

    '>aside': {
      display: 'flex',
      width: 'fit-content',
      minWidth: 0,
      gap: 1,

      bg: 'background',
      borderRadius: 'var(--radius)',
    },
  },

  lottie: {
    position: 'relative',
    '>div': {
      position: 'absolute',
      ...inset(0),
    },
  },

  //
  item: {
    fontSize: 1,

    width: 'calc(var(--width))',
    position: 'relative',

    image: borders,
    specs: {
      mt: 'calc(var(--stroke-x) * -1)',

      display: 'flex',
      flexDirection: 'column',
      gap: '2.5rem',

      bg: 'background',
      position: 'relative',
      zIndex: 2,

      '[data-spec="group"]': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.65em',
      },
      '[data-spec="title"],[data-spec="price"]': { variant: 'text.text' },
      ...borders,
      p: 1,
    },

    cta: {
      variant: 'text.cta',

      mt: 'calc(((var(--stroke-x) * 2) + var(--padding-x)) * -1)',
      pt: 'calc(var(--padding-x) * 2)',
      px: 1,
      pb: 1,

      ...borders,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      justifyContent: 'center',
      textAlign: 'center',

      transition: 'background-color var(--ui-duration) var(--ui-ease), color var(--ui-duration) var(--ui-ease)',

      '@media(hover: hover)': {
        '&[data-hoverable=true]:hover': {
          color: 'background',
          bg: 'text',
        },
      },
    },
  },
  //
  cursor: {
    position: 'absolute',
    size: '8em',
    color: 'background',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'popup',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    pointerEvents: 'none',

    '>div': {
      position: 'absolute',
      ...inset(0),
      zIndex: '-1',

      size: '100%',
      borderRadius: '50%',
      bg: 'primary',

      transformOrigin: 'center center',
    },
    '>*': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
}
