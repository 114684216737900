import { makeHighlight } from '@monobits/colors'

const opacity = 0.15
const sub_opacity = 0.4

const colors = {
  light: '#FCF9F8',
  dark: '#F25A1D',
}

export default {
  text: colors.dark,
  background: colors.light,
  primary: colors.dark,
  highlight: makeHighlight(colors.dark, opacity),
  subdued: makeHighlight(colors.dark, sub_opacity),
  burger_bg: colors.dark,
  burger_color: colors.light,
  ...colors,

  modes: {
    dark: {
      text: colors.light,
      background: colors.dark,
      primary: colors.dark,
      highlight: makeHighlight(colors.light, opacity),
      subdued: makeHighlight(colors.dark, sub_opacity),
      burger_bg: colors.light,
      burger_color: colors.dark,
    },
  },
}
