import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'text-indent': ['100px', '250px', false],
    'cta-size': ['120px', '260px', false],
    'gap-x': ['10px', '20px', false],
    'padding-x': ['20px', '20px'],
    'padding-m': ['60px', '140px'],
    'padding-l': ['160px', '400px'],
  },
  ['375px', '2500px']
)

export const rootScaleStyles = {
  ...fluid.properties,
  '--padding-x': '20px',
  '--stroke-x': '1px',
  '--stroke-s': '1px',
  '--stroke-m': '2px',

  '--logo-y': '2.85rem',
  '--logo-py': 'var(--padding-x)',
  '--header-height': 'calc(var(--logo-y) + (var(--logo-py) * 2))',
}

export default {
  space: fluid.scale,
  borderWidths: [0, 1, 2],
  sizes: {
    icon: '3.5rem',
  },
  zIndices: {
    below: -1,
    default: 1,
    nav: 10,
    popup: 20,
    panel: 30,
    modal: 40,
    scrollbar: 50,
  },
}
