import { kebabCase, startCase } from 'lodash'

export const handleize = kebabCase
export const titleCase = startCase

export const localize = (handle, locales) => {
  const key = kebabCase(handle)
  const locale = locales || null
  return locale[key] ? locale[key] : titleCase(key)
}
