import { useMemo } from 'react'
import { omit } from 'lodash'
import LottiePlayer from 'lottie-react'

const GalleryLottieX = ({ blok, ...props }) => {
  const { json } = blok ?? {}

  const lottie = useMemo(
    () =>
      !!json ? (
        <LottiePlayer
          animationData={JSON.parse(json ?? '{}')}
          autoplay={true}
          loop={true}
          {...omit(props, ['parentBlok'])}
        />
      ) : null,
    [json, props]
  )

  return lottie
}

export default GalleryLottieX
