import { atom } from 'recoil'

export const menuOpenState = atom({
  key: 'menuOpenState',
  default: false,
})

export const stickerActiveState = atom({
  key: 'stickerActiveState',
  default: false,
})

export const avatarsCycleState = atom({
  key: 'avatarsCycleState',
  default: 0,
})

export const faqSelectedState = atom({
  key: 'faqSelectedState',
  default: '',
})

export const faqDataState = atom({
  key: 'faqDataState',
  default: {},
})

export const faqDataAll = atom({
  key: 'faqDataAll',
  default: { data: [], current_index: 0 },
})

export const scrollPausedState = atom({
  key: 'scrollPausedState',
  default: false,
})
export const scrollActiveState = atom({
  key: 'scrollActiveState',
  default: true,
})
