import { forwardRef } from 'react'
import { Link } from 'gatsby'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box } from 'theme-ui'
import { Icon, Text } from '@monobits/components'
import { gsap } from 'gsap'

import { Button } from '../atoms'
import { menuOpenState, stickerActiveState } from '../states'
import Logo from '../../assets/svg/logo.svg'

const Header = forwardRef(({ sx, isNested = true, ...props }, ref) => {
  const [openned, setOpenned] = useRecoilState(menuOpenState)

  return (
    <header ref={ref} sx={{ variant: 'header', ...sx }} {...props}>
      <Box as={Link} to="/" sx={{ variant: 'header.logo' }} data-gsap="logo">
        <Icon icon={Logo} sx={{ width: 'unset' }} variant="scale" />
      </Box>
      <div sx={{ variant: 'header.bullets' }}>
        <Burger active={!openned} onClick={() => setOpenned((prev) => !prev)} className="-hide-desktop" />
      </div>

      <BurgerLong
        text={isNested ? 'Menu' : 'Close'}
        sx={{ gridColumn: '12/-1', justifyContent: 'end' }}
        active={!openned}
        onClick={() => setOpenned((prev) => !prev)}
        className="-show-desktop"
      />
    </header>
  )
})

const Bullets = ({ sx, ...props }) => {
  const setStickers = useSetRecoilState(stickerActiveState)

  const handleEnter = () => {
    setStickers(true)
    gsap.effects.userSelect('html', { value: 'none' })
  }
  const handleLeave = () => {
    setStickers(false)
    gsap.effects.userSelect('html', { value: true })
  }

  return (
    <div sx={{ variant: 'header.bullets.wrapper', ...sx }} {...props}>
      <div sx={{ variant: 'header.bullets' }}>
        <div sx={{ display: 'inline-block' }}>
          <Bullet onPointerEnter={handleEnter} onPointerLeave={handleLeave} />
        </div>
      </div>
    </div>
  )
}

function Bullet({ bg = 'var(--gradient)', sx, ...props }) {
  return (
    <button sx={{ variant: 'header.rainbow', ...sx }} aria-label="easter egg" data-gsap="sticker" {...props}>
      <div />
    </button>
  )
}

function Burger({ bg = 'text', active, sx, ...props }) {
  return (
    <button
      data-active={!!active}
      sx={{ variant: 'header.burger', ...sx }}
      data-gsap="burger"
      aria-label="menu"
      data-long={false}
      {...props}
    >
      <div data-fill />
    </button>
  )
}

function BurgerLong({ bg = 'text', text = 'Menu', active, sx, ...props }) {
  return (
    <Button
      variant="burger"
      size="slim"
      data-active={!!active}
      sx={{ variant: 'header.burger', ...sx }}
      data-gsap="burger"
      aria-label="menu"
      data-long={true}
      cropped={false}
      {...props}
    >
      <Text as="span">{text}</Text>
      <div data-fill />
    </Button>
  )
}

Header.Bullets = Bullets

export default Header
