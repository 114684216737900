import { Icon } from '@monobits/components'

import Discord from '../../assets/svg/discord.svg'
import Instagram from '../../assets/svg/instagram.svg'
import Medium from '../../assets/svg/medium.svg'
import Twitter from '../../assets/svg/twitter.svg'

import Cross from '../../assets/svg/cross.svg'
import ArrowUp from '../../assets/svg/arrow-up.svg'

import NotFound404 from '../../assets/svg/404.svg'

const icons = {
  discord: Discord,
  instagram: Instagram,
  medium: Medium,
  twitter: Twitter,
  cross: Cross,
  'arrow-up': ArrowUp,
  404: NotFound404,
}

const Icons = ({ name, ...props }) => name in icons && <Icon icon={icons[name]} {...props} />

export default Icons
