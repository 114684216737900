const borders = {
  borderWidth: 'var(--stroke-x, 1px)',
  borderColor: 'subdued',
  borderStyle: 'solid',

  borderRadius: 'var(--padding-x)',
}

export default {
  __default: {
    position: 'relative',

    '--gap': ['1rem', 'var(--padding-x)'],
    '--gap-offset': 'calc((100% + var(--gap)) * -1)',
  },
  button: {
    position: 'absolute',
    top: 'calc((var(--cta-size) * 0.6) * -1)',
    zIndex: '9',
    '--offset': '12vw',

    transform: 'scale(1)',

    '&[data-alignment="left"]': { left: 'var(--offset)' },
    '&[data-alignment="right"]': { right: 'var(--offset)' },
  },
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--gap)',

    py: 'var(--stroke-x)',
    backfaceVisibility: 'hidden',
    transform: 'translateZ(0)',
  },
  row: {
    '&[data-hidden=true]': {
      visibility: 'hidden',
    },
    display: 'grid',
    willChange: 'transform',

    '>aside': { position: 'relative' },
    aside: {
      display: 'flex',
      width: 'fit-content',
      minWidth: 0,

      gap: 'var(--gap)',
    },

    '&[data-inverted=false]>aside': {
      justifySelf: 'start',
      '>aside': { right: 'var(--gap-offset)' },
    },
    '&[data-inverted=true]>aside': {
      justifySelf: 'end',
      '>aside': { left: 'var(--gap-offset)' },
    },

    '[data-ready=false] &': { opacity: 0 },
    transition: 'opacity var(--ui-duration) var(--ui-ease)',
  },
  cell: {
    width: 'calc(100vw / var(--cell-span, 8))',
    display: 'inline-flex',

    ...borders,
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
}
