import { createElement, forwardRef } from 'react'
// import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useThemeUI } from 'theme-ui'

const ScrollTo = forwardRef(
  ({ forwardedAs = 'button', top, anchor, speed, offset = '15%', onClick = () => {}, ...props }, ref) => {
    const { scrollDuration } = useThemeUI().theme?.config?.locomotive?.extends ?? {}
    // const { scroll } = useLocomotiveScroll()

    // const duration = speed != null ? speed : scrollDuration

    const handleClick = () => {
      onClick()
      // if (!!anchor) return scroll.scrollTo(`[data-anchor=${anchor}]`, { duration, offset })
      // if (!!top) return scroll.scrollTo(0, { duration, offset: 0 })
      return console.warn('ScrollTo component is missing a "anchor" or "top" prop.')
    }

    return createElement(forwardedAs, { ref, onClick: handleClick, ...props })
  }
)

export default ScrollTo
