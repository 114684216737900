const grid = {
  display: 'grid',
  gridTemplateColumns: 'repeat(var(--columns), 1fr)',
  columnGap: 'var(--column-gap)',
  rowGap: [1, 2, 3],
}

export default {
  __default: {
    ...grid,

    '--columns': 8,
    '--column-gap': 'var(--padding-x)',

    mt: 1,

    // '[data-media-type="image"],[data-media-type="video"]': {
    //   bg: 'var(--cycle-color, transparent)',
    // },

    '[data-media-type="image"]>*,[data-media-type="video"]>*': {
      size: 'calc(100% + 2px)',
      m: '-1px',
    },

    '[data-fit=cover]': { '--fit': 'cover' },
    '[data-fit=contain]': { '--fit': 'contain' },

    '[data-scroll-direction="left-right"]': {
      '--direction': 'row',
      '--pos': '0%',
    },
    '[data-scroll-direction="right-left"]': {
      '--direction': 'row-reverse',
      '--pos': '100%',
    },

    '[data-size="full"],[data-size="half-full"],[data-size="large"]': { gridColumn: 'span var(--columns)' },

    '[data-size="half"]': {
      gridColumn: ['span var(--columns)', null, 'span calc(var(--columns) * 0.5)'],
    },
    '[data-size="large"]': {
      '--inner-width': ['100%', null, '75%'],
    },
    '[data-direction="left-right"][data-size="large"],[data-direction="left-right"][data-size="half-full"]': {
      justifyContent: 'end',
    },
    '[data-direction="right-left"][data-size="large"],[data-direction="right-left"][data-size="half-full"]': {
      justifyContent: 'start',
    },
    '[data-from-direction="left-right"][data-from-size="large"]': {
      gridColumn: ['1/-1', null, '2/-1'],
    },
    '[data-from-direction="right-left"][data-from-size="large"]': {
      gridColumn: ['1/-1', null, '1/8'],
    },

    '[data-size][data-has-content=true],[data-size="half-full"]': {
      gridColumn: 'span var(--columns)',
      '--inner-width': ['100%', null, 'calc(50% - (var(--column-gap) / 2))'],
    },

    '[data-size]': {
      height: 'fit-content',
      display: 'flex',
      flexDirection: ['column', null, 'var(--direction)'],
      gap: 'var(--column-gap)',

      '>*:not(button):not(a):not([data-cta])': { width: 'var(--inner-width, 100%)' },
    },

    '[data-scoped-section]': {
      position: 'relative',
      alignItems: 'center',
      gridColumn: '1/-1',
      ...grid,

      '>div': {
        gridRow: [null, null, '1/2'],
      },
      '>[data-is-scoped]': {
        gridColumn: ['1/-1', null, 'unset'],
        gridRow: ['2/3', null, 'unset'],
        position: [null, null, 'absolute'],
        width: '100%',

        // '>div': {
        //   bg: 'var(--cycle-color-next, transparent)',
        // },
      },

      '&[data-from-direction="right-left"][data-from-size="large"]>[data-is-scoped]': {
        gridColumn: ['1/-1', null, 'calc((var(--columns) + 1) - var(--span, 3))/-1'],
      },
      '&[data-from-direction="left-right"][data-from-size="large"]>[data-is-scoped]': {
        gridColumn: ['1/-1', null, '1/calc((var(--columns) - 1) - var(--span, 3))'],
      },
      '&[data-from-direction="right-left"][data-from-size="half-full"]>[data-is-scoped]': {
        gridColumn: ['1/-1', null, 'calc((var(--columns) - 1) - var(--span, 3))/6'],
      },
      '&[data-from-direction="left-right"][data-from-size="half-full"]>[data-is-scoped]': {
        gridColumn: ['1/-1', null, '4/calc((var(--columns) + 1) - var(--span, 3))'],
      },
    },
  },
}
