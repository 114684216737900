import { createContext } from 'react'
import { useSetRecoilState } from 'recoil'
import { getWindow } from 'ssr-window'
import { Box, useThemeUI } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useSessionStorageValue } from '@react-hookz/web'
import { ColorModeTransition } from '@monobits/color-transitions'
import { ObserverProvider } from '@boiler/intersection-observer'
import { ImageProvider } from '@boiler/image'
import { VideoProvider } from '@boiler/hls'
import { MergedComponent } from '@boiler/utils'
import { Transition } from '@boiler/framer'
import Smoother from '@boiler/smoother'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import Stickers from '../components/Stickers'
import Intro from '../components/Intro'

import Sizes from './Sizes'
import useStoryblok from './useStoryblok'
import definitions from '../definitions'

import { FaqPanel } from '../components'
import { useSetResponsiveAttr } from '../hooks'
import { scrollActiveState } from '../states'

const window = getWindow()
export const StoryblokContext = createContext()
export const ComponentsContext = createContext()

const Layout = ({ children, pageContext, location }) => {
  const { transitions = {} } = useThemeUI().theme ?? {}
  const { settings, locales = {} } = useStoryblok(pageContext, location)
  const { lang } = pageContext?.story ?? {}

  const [rendered, setRendered, removeRendered] = useSessionStorageValue('app-rendered', false, {
    handleStorageEvent: true,
    isolated: false,
  })

  const setScrollActive = useSetRecoilState(scrollActiveState)

  const breakpoint = useBreakpointIndex()

  window.onunload = () => removeRendered()

  useSetResponsiveAttr()

  const inEditor = !!location?.search?.includes('_storyblok')

  return (
    <>
      <ColorModeTransition />
      <Sizes callbacks={location} />

      <Smoother.Provider
        id="root"
        prefix=""
        wrapper={[Box, { as: 'main', sx: { display: 'flex', flexDirection: 'column' } }]}
        content={['div']}
        configs={{
          smooth: 1.2,
          effects: true,
          normalizeScroll: true,
          ignoreMobile: true,
          // ignoreMobileResize: true,
          onUpdate: (self, { set, get }) => {
            set.scrollDirection(self)
            set.scrollPosition(self, { get, rootMargin: '28%' })
          },
        }}
        onMount={(enabled) => {
          !!enabled && setScrollActive(true)
        }}
        onUnMount={(enabled) => {
          !!enabled && setScrollActive(false)
        }}
      >
        {({ ids }) => (
          <ObserverProvider
            options={{
              root: ids?.wrapper,
              offset: '-12%',
            }}
            inview={{ once: true, disabled: !!inEditor }}
          >
            <StoryblokContext.Provider
              value={{ settings, locales, lang, breakpoint, rendered: [rendered, setRendered] }}
            >
              <div id="p5_loading" className="loadingclass">
                <span />
              </div>

              <MergedComponent
                components={[ImageProvider, VideoProvider]}
                value={{
                  steps: 400,
                  params: { quality: 92 },
                  placeholder: false,
                  // placeholder_modifiers: '/1x1/filters:quality(1)',
                  placeholder_modifiers: '/300x/filters:quality(50):blur(12)',
                  transition: 'var(--ui-duration) var(--ui-ease)',
                  fade: true,
                }}
              >
                <ComponentsContext.Provider value={definitions}>
                  <Transition.Provider lang={lang} transitions={transitions}>
                    <FaqPanel watch={[location?.key]} />

                    {!rendered && <Intro />}

                    <Menu watch={[location?.key]} />

                    <Stickers images={settings?.stickers ?? []} />

                    <Header.Bullets />

                    <Smoother.Wrapper>
                      <Header
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          zIndex: 'nav',
                        }}
                      />
                      <div id="main" sx={{ bg: 'background' }}>
                        <Transition.Page
                          as="div"
                          location={location}
                          // onAnimationStart={() => setTimeout(resetTheme, (transitions?.exit ?? 0.45) * 2000)}
                        >
                          <div id="content-holder">{children}</div>

                          <Footer />
                        </Transition.Page>
                      </div>
                    </Smoother.Wrapper>
                  </Transition.Provider>
                </ComponentsContext.Provider>
              </MergedComponent>
            </StoryblokContext.Provider>
          </ObserverProvider>
        )}
      </Smoother.Provider>
    </>
  )
}

export default Layout
