import SbEditable from 'storyblok-react'
import { useSmoothEffect } from '@boiler/smoother'
import { useBreakpoint, multiLink } from '@boiler/utils'

import { MapBloks } from '../../bloks'
import { Button } from '../../atoms'
import GalleryBase from './GalleryBase'

const inviewProps = { 'data-scroll-offset': '25%,0' }

const GalleryBlock = ({
  blok,
  sx,
  cycleStyles = {},
  autoplay = false,
  index: i = 0,
  sanitize = (p) => p,
  ...props
}) => {
  const {
    asset,
    content,
    scope = [],
    size,
    direction,
    fit,
    ratio,
    multi_ratio,
    align_x,
    align_y,
    mobile_push_back,
    dynamic_width,
    dynamic_alignment,
    cta_use,
    _uid,
  } = blok ?? {}
  const { breakpoint, index } = useBreakpoint()

  const _width =
    size === 'half' ? (dynamic_width != null && dynamic_width?.value !== 100 ? dynamic_width?.value + '%' : null) : null
  const _justify = size === 'half' ? dynamic_alignment : null

  const cta_enabled = !!(cta_use === 'true')

  const cleanup = content?.content?.filter((i) => 'content' in i)
  const hasContent = !!content && !!cleanup?.length
  const media_type = asset?.[0]?.component
  const scope_media_type = scope?.[0]?.component

  const list = multi_ratio?.list ?? []
  const get_ratio = list?.map((i) => (i?.text === 'default' ? null : i?.text))?.filter(Boolean)
  const has_ratio = !!get_ratio?.length
  const ratios = has_ratio ? get_ratio : !!ratio ? ratio : null
  const position = `${align_x ?? 'center'} ${align_y ?? 'center'}`

  const hasScope = Array.isArray(scope) && !!scope?.length
  const prevent_scope = ['full', 'half'].every((i) => size !== i) && !!!hasContent

  const newOrderValue = !!mobile_push_back
    ? +mobile_push_back < 0
      ? +mobile_push_back - 1
      : +mobile_push_back + 1
    : null
  const newOrder = breakpoint?.name != null && breakpoint?.name !== 'desktop' ? newOrderValue : null

  const body = (
    <GalleryBase
      sx={{
        ...cycleStyles,
        mt: !!cta_enabled && !!(index <= 1) && 'calc(var(--cta-size) / 2)',
        ...sx,
      }}
      style={{
        order: newOrder != null ? `calc(var(--order) + ${newOrder})` : null,
        justifyContent: _justify,
        position: 'relative',
      }}
      blok={blok}
      {...sanitize(props)}
    >
      <div
        sx={{ width: [null, null, _width + '!important'] }}
        data-media-type={media_type}
        data-inview="media"
        {...inviewProps}
      >
        {!!asset && (
          <MapBloks
            id={_uid}
            items={asset}
            childProps={{
              image: { fit, ratio: ratios, position },
              video: {
                fit,
                ratio: ratios,
                position,
                autoplay: true,
                eager: i === 0,
                lazy: { play: true, once: false },
              },
              lottie: { autoplay: true, loop: true },
            }}
          />
        )}
      </div>
      {index != null && !!cta_enabled && !!(index > 1) && (
        <Cta
          link={blok?.cta_link ?? {}}
          x={blok?.cta_x?.value}
          y={blok?.cta_y?.value}
          speed={blok?.cta_smooth?.value ?? 1}
          layout="round"
        >
          {blok?.cta_text}
        </Cta>
      )}
      {index != null && !!cta_enabled && !!(index <= 1) && (
        <SimpleCta link={blok?.cta_link ?? {}} alignment={blok?.cta_alignment} layout="round">
          {blok?.cta_text}
        </SimpleCta>
      )}
    </GalleryBase>
  )

  return (
    <SbEditable content={blok} key={_uid}>
      {!!hasScope && prevent_scope && breakpoint !== 'mobile' ? (
        <div
          style={{ '--span': 3 }}
          sx={{ ...cycleStyles, ...sx }}
          data-scoped-section
          data-from-size={size}
          data-from-direction={direction}
        >
          {body}

          <div data-is-scoped>
            <MapBloks
              wrapperProps={{
                'data-media-type': scope_media_type,
                'data-inview': 'media',
                ...inviewProps,
              }}
              items={scope}
              id={_uid}
            />
          </div>
        </div>
      ) : (
        body
      )}
    </SbEditable>
  )
}

const Cta = ({ children, sx, speed = 1, link = {}, x = 0, y = 0, ...props }) => {
  const [ref, attributes] = useSmoothEffect({}, { speed })

  return (
    <div
      ref={ref}
      data-cta
      sx={{ position: 'absolute', zIndex: 11, ...sx }}
      style={{ top: y + '%', left: x + '%' }}
      {...attributes}
    >
      <Button
        sx={{ variant: 'showcase.button', position: 'unset' }}
        layout="round"
        {...(!!link?.cached_url ? multiLink(link) : {})}
        {...props}
      >
        {children}
      </Button>
    </div>
  )
}

const SimpleCta = ({ children, sx, link = {}, alignment = 'left', ...props }) => {
  return (
    <div
      data-cta
      sx={{ position: 'absolute', top: 0, [alignment]: 1, zIndex: 11, transform: 'translateY(-50%)', ...sx }}
    >
      <Button sx={{ variant: 'showcase.button', position: 'unset' }} layout="round" {...multiLink(link)} {...props}>
        {children}
      </Button>
    </div>
  )
}

export default GalleryBlock
