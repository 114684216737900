import { MapBloks } from '../../bloks'
import { useData } from '../../hooks'
import Component from './Menu.component'

const Menu = ({ watch = [] }) => {
  const { settings } = useData() ?? {}
  const { menu, social_nav, copyrights } = settings ?? {}

  if (!settings) return null

  return (
    <Component
      watch={watch}
      navigation={[<MapBloks items={menu} type="menu" />].filter(Boolean)}
      secondary={[
        <div sx={{ display: 'flex', gap: '0.75rem' }}>
          <MapBloks items={social_nav} />
        </div>,
        <MapBloks items={copyrights} sx={{ alignSelf: 'end' }} />,
      ]}
    />
  )
}

export default Menu
