import { keyframes } from '@emotion/react'
import { inset } from '@monobits/core'

const spin = keyframes({ from: { transform: 'rotate(0deg)' }, to: { transform: 'rotate(360deg)' } })

const bullet = {
  userSelect: 'none',
  p: 'var(--p-offset)',
  '--size': 'var(--padding-x)',
  '--radius': '50%',
}

export default {
  __default: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: 1,
    p: 1,

    alignItems: ['start', null, 'center'],

    zIndex: 14,
  },
  logo: {
    gridColumn: ['1/8', null, '1/3'],
    height: 'var(--logo-y)',
    width: 'fit-content',
  },
  bullets: {
    my: 'var(--offset)',
    mx: 'var(--offset)',
    '--offset': 'calc(var(--p-offset) * -1)',

    gridColumn: ['13/-1', null, '3/9'],
    textAlign: ['right', null, 'center'],

    display: 'flex',
    justifyContent: ['space-between', null, 'center'],
    alignItems: 'center',

    height: 'var(--logo-y)',

    wrapper: {
      position: 'fixed',
      top: 'calc(var(--padding-x))',
      left: ['75%', null, '60%'],
      zIndex: 13,
    },
  },
  nav: {
    gridColumn: '9/-1',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
  },
  burger: {
    userSelect: 'none',
    p: 'var(--p-offset)',
    '--radius': '50%',

    '&[data-long=false]': {
      '--size': 'var(--padding-x)',
      '--fill-bg': (t) => t.colors?.background,
      '--fill-fg': (t) => t.colors?.text,
    },
    '&[data-long=true]': {
      '--size': '1.5rem',
      '--fill-bg': 'var(--bg)',
      '--fill-fg': 'var(--text)',

      '>span': {
        display: 'flex',
        alignItems: 'center',
        gap: '2.5em',

        my: '-0.15em',

        '::before,::after': {
          display: 'none',
        },
      },

      '[data-fill]': {
        my: '-0.25em',
      },
    },

    '[data-fill]': {
      position: 'relative',

      borderWidth: 'var(--stroke-x)',
      borderStyle: 'solid',
      borderColor: 'var(--fill-fg)',

      bg: 'var(--fill-bg)',
      size: 'var(--size)',
      borderRadius: 'var(--radius)',

      '::before': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',

        bg: 'var(--fill-fg)',
        transform: 'scale(var(--to))',
        ...inset(0),

        transition: 'transform var(--ui-duration) var(--ui-ease)',
        willChange: 'transform',
      },
    },

    '&[data-active=true]': { '--to': '0.55' },
    '&[data-active=false]': { '--to': '1.05' },

    '@media (hover: hover)': {
      '&:hover [data-fill]::before': { transform: 'scale(1.05)' },
    },
  },
  //
  rainbow: {
    ...bullet,

    '>div': {
      size: 'var(--size)',
      borderRadius: 'var(--radius)',
      background: 'var(--gradient)',
    },

    '@media (hover: hover)': {
      '&:hover>div': {
        animationName: spin,
        animationDuration: '0.15s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
      },
    },
  },
}
