import { Button } from '@monobits/components'
import { multiLink } from '@boiler/utils'
import { Icons } from '../atoms'

const SocilaLink = ({ children, blok, type = 'nav', ...props }) => {
  const { link, icon } = blok ?? {}

  return (
    <Button variant="secondary" size="iconsMd" {...props} {...multiLink(link)}>
      <Icons name={icon} />
    </Button>
  )
}

export default SocilaLink
