import { memo } from 'react'
import { Flex } from 'theme-ui'
import { Image as StoryblokImage } from '@boiler/image'
import { Lazy } from '@boiler/intersection-observer'
import { multiLink } from '@boiler/utils'

import { Rte } from '../../atoms'
import { MapBloks } from '../../bloks'

const Card = ({
  asset,
  media = [],
  title,
  price,
  currency,
  cta,
  cta_link = {},
  description,
  ratio,
  bindHover,
  ...props
}) => {
  const has_media = !!media?.length

  const image = has_media ? (
    <div
      data-aspect-ratio
      sx={{
        variant: 'slider.lottie',
        '--aspect-ratio': ratio ? ratio : null,
      }}
    >
      <MapBloks items={media} id={props?._uid ?? 'media'} />
    </div>
  ) : (
    <Lazy inview={{ once: true }} eager={false}>
      {({ ref, inview }) => (
        <StoryblokImage
          ref={ref}
          data={asset}
          ratio={ratio ? ratio : null}
          inview={inview}
          object={{ fit: 'contain', position: 'center center' }}
          fitted={false}
        />
      )}
    </Lazy>
  )

  const listing_price = {
    CAD: `$${price} CAD`,
    USD: `$${price} USD`,
    UST: `${price} (UST)`,
    LUNA: `${price} (LUNA)`,
  }[currency ?? 'CAD']

  const button = {
    soon: {
      children: 'Coming Soon',
      'data-hoverable': false,
    },
    shop: {
      children: 'Shop Now',
      'data-hoverable': true,
      ...multiLink(cta_link, false, !!!cta_link?.cached_url),
      ...bindHover,
    },
  }[cta ?? 'soon']

  return (
    <article sx={{ variant: 'slider.item' }}>
      <div sx={{ variant: 'slider.item.image' }} data-column>
        {!!asset?.filename && image}
      </div>

      <div sx={{ variant: 'slider.item.specs' }} data-column>
        <div data-spec="group">
          <h3 data-spec="title">{title}</h3>
          {!!+price && <h5 data-spec="price">{listing_price}</h5>}
        </div>
        <Rte content={description} />
      </div>

      <Flex sx={{ variant: 'slider.item.cta' }} {...button} />
    </article>
  )
}

export default memo(Card)
