import { Icon } from '@monobits/components'

import { MapBloks } from '../bloks'
import { useData } from '../hooks'
import HeadingBlok from './Heading'
import Logo from '../../assets/svg/logo.svg'

const Footer = () => {
  const { settings } = useData() ?? {}
  const { social_nav, footer_caption, footer_text } = settings ?? {}

  if (!settings) return null

  return (
    <footer sx={{ variant: 'footer' }} data-column>
      <HeadingBlok
        cProps={{ sx: { variant: 'footer.head', my: '0px!important', px: '0px!important' } }}
        blok={{ caption: footer_caption, content: footer_text, _uid: 'footer-heading' }}
      />

      <section sx={{ variant: 'footer.foot' }}>
        <div>
          <Icon icon={Logo} sx={{ height: 'calc(var(--logo-y) * 0.8)', width: 'unset' }} variant="scale" />
        </div>

        <nav sx={{ display: 'grid', gridAutoFlow: 'column', gridGap: '1em', order: 4 }}>
          <MapBloks items={social_nav} variant="primary" size="icons" />
        </nav>
      </section>
    </footer>
  )
}

export default Footer
