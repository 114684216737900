import { Grid, Box, Flex } from 'theme-ui'
import { Section, Heading, SkewLink } from '@monobits/components'
import { Head } from '@monobits/molecules'
import { omit, kebabCase } from 'lodash'
import { Underline } from './atoms'

// Func, sanitizes the props to prevent DOM side-effects.
const _ = (obj) => omit(obj, ['lang'])

/**
 * Define the components independently from their logic.
 */
export default {
  links: ({ value, sx, ...props }) => (
    <div sx={{ display: 'grid', gridGap: 'var(--nav-gap)', ...sx }} {..._(props)}>
      {value}
    </div>
  ),
  language_switcher: ({ value, sx, ...props }) => (
    <Underline variant="menu" sx={{ display: 'flex', ...sx }} {..._(props)}>
      {value.toUpperCase()}
    </Underline>
  ),
  nav_item: ({ value, ...props }) => (
    <Underline variant="menu" {..._(props)}>
      {value}
    </Underline>
  ),
  menu_item: ({ value, ...props }) => <SkewLink {..._(props)}>{value}</SkewLink>,
  running_text: ({ value, multiple = false, ...props }) => (
    <Section variant="running" data-multiple={multiple} {..._(props)} data-scroll data-fade>
      {value}
    </Section>
  ),
  columns_text: ({ value, multiple = false, ...props }) => (
    <div data-multiple={multiple} {..._(props)}>
      {value}
    </div>
  ),
  heading: ({ value, anchor, wrapperProps = {}, ...props }) => (
    <Section variant="heading" {..._(wrapperProps)} data-scroll data-fade>
      {anchor}
      <Heading variant="title" {..._(props)}>
        {value}
      </Heading>
    </Section>
  ),
  gallery: ({ value, sx, ...props }) => (
    <Section sx={{ variant: 'gallery', ...sx }} {..._(props)}>
      {value}
    </Section>
  ),
  head: ({ text, ...props }) => (
    <Head {..._(props)}>
      {text && (
        <Heading as="h1" variant="title">
          {text}
        </Heading>
      )}
    </Head>
  ),
  collapsible: {
    auto: true,
    head: ({ value, content, isLink, showIcon = true, id, sx, props }) => {
      const multiple = Array.isArray(value)

      return (
        <Flex sx={{ variant: 'collapsible', width: '100%', ...sx }} {...props.wrapper} data-scroll data-fade>
          <Grid
            sx={{ variant: 'collapsible.head', '--length': multiple ? value.length : 1 }}
            data-multiple={multiple ? !!(value.length > 1) : false}
            data-standalone={false}
            {...props.button}
          >
            {multiple ? (
              value?.map((v, i) => (
                <Box key={id + kebabCase(value) + i} as="h3" sx={{ variant: 'collapsible.text' }}>
                  {v?.text ?? v}
                </Box>
              ))
            ) : (
              <Box as="h3" sx={{ variant: 'collapsible.text' }}>
                {value}
              </Box>
            )}

            <Flex sx={{ variant: 'collapsible.icon', visibility: !!!showIcon && 'hidden' }}>
              <Flex as="aside" />
            </Flex>
          </Grid>

          {!!!isLink && content}
        </Flex>
      )
    },
    section: { variant: 'faq' },
    rte: { sx: { variant: 'collapsible.rte' } },
    services: { variant: 'collapsible.service_list' },
  },
  obfuscate: {
    component: ({ value, ...props }) => <Underline {..._(props)}>{value}</Underline>,
    email: { pre: null },
    phone: { pre: '[ T ]:\u00A0' },
  },
}
