import { useRef } from 'react'
import { useResizeObserver, useDebouncedState } from '@react-hookz/web'

const useDebouncedRect = (delay = 300, maxWait = 500) => {
  let ref = useRef(null)
  const [rect, setRect] = useDebouncedState({}, delay, maxWait)

  useResizeObserver(ref, (e) => setRect(e.contentRect))

  return [ref, rect]
}

export default useDebouncedRect
