import { useRecoilState, useSetRecoilState } from 'recoil'
import SbEditable from 'storyblok-react'
import Table from '../Table'

import { faqSelectedState, faqDataState, faqDataAll } from '../../states'

const FaqSection = ({ blok, sx, settings, locales, sanitize, ...props }) => {
  const { list, _uid } = blok ?? {}

  const [all, setAll] = useRecoilState(faqDataAll)
  const [selected, setSelected] = useRecoilState(faqSelectedState)
  const setData = useSetRecoilState(faqDataState)

  const sorted = list?.slice()?.sort(() => -1)

  const handleClick = (data, i) => {
    setData(data)
    setSelected((prev) => (prev === data?.slug ? '' : data?.slug))

    if (all?.length !== sorted) setAll({ data: sorted, current_index: i })
  }

  return (
    <SbEditable content={blok} key={_uid}>
      <Table auto_close={true} {...sanitize(props)}>
        {sorted?.map((item, i) => (
          <Table.Cell
            as="button"
            title={item?.content?.title ?? ''}
            key={'listitem' + item?.slug + i}
            onClick={() => handleClick(item, i)}
            isSelected={!!(item?.slug === selected)}
            showIcon={true}
          />
        ))}
      </Table>
    </SbEditable>
  )
}

export default FaqSection
