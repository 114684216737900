export default {
  __default: {
    display: 'flex',
    flexDirection: 'column',

    '@media (hover: hover)': {
      gap: '3rem',
    },
    '@media (hover: none)': {
      gap: 'calc(var(--button-size) * 0.8)',
    },

    '[data-img],[data-video]': {
      borderRadius: 'var(--padding-x)',
      transform: 'translateZ(0)',
      overflow: 'hidden',
    },

    '--button-size': '11em',
  },
  head: {
    display: 'grid',

    '[data-view="full"] &,[data-view="page"] &': {
      gridTemplateColumns: ['1fr', 'repeat(6, 1fr)'],
      gap: 1,

      '>[data-scope]': {
        gridColumn: 'span 2',
      },
      '>[data-scope="description"]': {
        gridColumn: ['span 4', null, 'span 2'],
      },
    },
    '[data-view="dense"][data-break="desktop"] &': {
      gridTemplateColumns: 'auto minmax(250px, 20rem) 3fr',
      gap: 2,
    },
    '[data-view="dense"][data-break="mobile"] &': {
      gridTemplateColumns: '1fr',
      gap: ['calc(var(--padding-x) * 2)', null, 2],
    },
  },
  asset: {
    position: 'relative',

    '[data-view="full"] &': {
      '@media (hover: hover)': { cursor: 'none' },
    },
  },

  gallery: {
    gridColumn: '1/-1',
    display: 'grid',

    gridTemplateColumns: ['repeat(8, 1fr)', null, 'repeat(22, 1fr)'],
    gridTemplateRows: ['auto 12rem 1fr', 'auto 8rem 1fr'],

    alignItems: 'start',

    gap: [1, null, 0],

    '>div': {
      gridRow: [null, null, '1/3'],
    },

    '>div:nth-of-type(1)': {
      gridColumn: ['1/7', null, '1/11'],
      mt: ['calc(var(--padding-x) * 3)', null, 0],
    },
    '>div:nth-of-type(2)': {
      gridColumn: ['6/9', null, '10/14'],
      gridRow: [null, null, '2/4'],
      alignSelf: 'end',
    },
    '>div:nth-of-type(3)': {
      gridColumn: ['2/7', null, '18/23'],
      mt: ['calc((var(--padding-s) * 1.5) * -1)', null, 0],
      mb: ['calc((var(--padding-m) * 1.8) * -1)', null, 0],
    },

    mb: [null, null, 'calc((var(--padding-m) * 2) * -1)'],
  },

  cursor: {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 1,

    '@media (hover: hover)': {
      top: 0,
      left: 0,
    },
    '@media (hover: none)': {
      top: 'calc((var(--button-size) / 2) * -1)',
      right: 1,
      transform: 'none!important',
      opacity: '1!important',
      visibility: 'visible!important',
    },

    p: '1em',
    size: 'var(--button-size)',
    borderRadius: '50%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    bg: 'text',
    color: 'background',
  },
}
