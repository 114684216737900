import { useRecoilValue } from 'recoil'
import { Container } from '@monobits/components'
import { useScrollSmoother } from '@boiler/smoother'

import { Bloks } from '../bloks'
import Page from './Page'
import NotFound from './404'
import Lookbook from './Lookbook'
import { scrollPausedState } from '../states'

const Template = ({ component, ...props }) => {
  const Component =
    {
      global: Bloks,
      'not-found': NotFound,
      page: Page,
      lookbook: Lookbook,
    }[component] || Bloks

  const paused = useRecoilValue(scrollPausedState)
  useScrollSmoother(!!!paused)
  // useScrollSmoother(false)

  // const minimal = component === 'not-found'

  if (component === 'global') {
    return (
      <Container variant="box" data-inview="false">
        <Component {...props} />
      </Container>
    )
  }

  return <Component {...props} />
}

export default Template
