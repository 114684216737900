import { Link } from 'gatsby'
import { Button } from '../atoms'

const LanguageSwitcher = ({ alternates, iso: lang, variant = 'primary', ...props }) => {
  if (!alternates) return null

  return Object.entries(alternates)
    .filter((iso) => iso[0] !== lang)
    .map(([key, value]) => (
      <Button variant="stroke" size="sm" as={Link} key={'lang' + key} to={value || '/'} {...props}>
        {key.toUpperCase()}
      </Button>
    ))
}

export default LanguageSwitcher
