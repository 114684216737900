import SbEditable from 'storyblok-react'
import { memo } from 'react'
import { useThemeUI } from 'theme-ui'
import { Bloks } from '../../bloks'
import { useComponent } from '../../hooks'

const Gallery = ({ blok, sanitize = (p) => p, ...props }) => {
  const { body, _uid } = blok ?? {}
  const { gallery } = useComponent() ?? {}
  const { cycle } = useThemeUI()?.theme?.colors ?? {}

  const content =
    body &&
    body.map((item, index) => {
      const bloks = (
        <Bloks
          cycleStyles={{
            '--cycle-color': !!cycle?.length
              ? `var(--theme-ui-colors-cycle-${index % cycle?.length}, inherit)`
              : `var(--theme-ui-colors-highlight, transparent)`,
            '--cycle-color-next': !!cycle?.length
              ? `var(--theme-ui-colors-cycle-${(index + 1) % cycle?.length}, inherit)`
              : `var(--theme-ui-colors-highlight, transparent)`,
            '--order': index + 1,
            order: 'var(--order)',
          }}
          blok={item}
          sanitize={sanitize}
          autoplay={!!(index === 0)}
          index={index}
          key={item._uid}
          {...props}
        />
      )

      return bloks
    })

  return body ? (
    <SbEditable content={blok} key={_uid}>
      {gallery({ value: content, ...sanitize(props) })}
    </SbEditable>
  ) : null
}

export default memo(Gallery)
