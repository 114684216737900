import { useRecoilState } from 'recoil'
import { ReactP5Wrapper } from '@boiler/p5'
import { useIdleTimer } from 'react-idle-timer'

import { stickerActiveState } from '../../states'
import sketch from './sketch'

const Stickers = ({ images, sx, ...props }) => {
  const [active, setActive] = useRecoilState(stickerActiveState)

  const enabled = process.env.GATSBY_AUTOTRIGGERS === 'true'
  useIdleTimer({
    onIdle: () => (enabled ? setActive(true) : {}),
    onActive: () => (enabled ? setActive(false) : {}),
    timeout: 1000 * 26,
    debounce: 500,
  })

  const medias = images?.map(({ filename }) => filename)

  return (
    <aside
      aria-hidden={!!!active}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 'popup',

        width: '100vw',
        height: 'calc(var(--vh-x, 1vh) * 100)',

        userSelect: 'none',
        pointerEvents: 'none',
        '&[aria-hidden=true]': { visibility: 'hidden' },

        ...sx,
      }}
      {...props}
    >
      <ReactP5Wrapper sketch={sketch} active={active} paths={medias} />
    </aside>
  )
}

export default Stickers
