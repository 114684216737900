import { Button as Atom, Text } from '@monobits/components'
import { setVariantString } from '@monobits/theme-core'

import Icons from './Icons'

const Button = ({ icon, children, layout, sx, font = { variant: 'text' }, ...props }) => {
  const alignment = icon ? 'withicon' : layout

  return (
    <Atom sx={{ '&': { variant: setVariantString('button.layout', alignment) }, ...sx }} {...props}>
      <div>{icon && <Icons name={icon} />}</div>
      <Text as="span" {...font}>
        {children}
      </Text>
    </Atom>
  )
}

export default Button
