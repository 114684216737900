import { useRef, useState, useMemo, memo } from 'react'
import { useUpdateEffect } from '@react-hookz/web'
import { useRecoilValue } from 'recoil'
import { Heading } from '@monobits/components'
import { Image as StoryblokImage } from '@boiler/image'
import { Lazy } from '@boiler/intersection-observer'
import { gsap } from 'gsap'
import { motion } from 'framer-motion'

import Foot from './Foot'
import { avatarsCycleState } from '../../states'

const x = {
  range: 1,
  duration: 1,
  // duration: 2.2,
  ease: 'power3.out',
  interval: 6500,
}

const Hero = ({ head = [], assets = {}, links = {}, uid = '', options = x }) => {
  const [index, setIndex] = useState(0)
  const cycle = useRecoilValue(avatarsCycleState)

  const medias = assets

  let indexes = useRef(Array.from({ length: medias.length }, (_, index) => index))
  let randomized = useRef(shuffle(indexes.current))
  let score = useRef({ value: index })
  let interacted = useRef(false)

  const handleClick = () => {
    interacted.current = true
    tween()
  }

  const tween = () =>
    gsap.fromTo(
      score.current,
      { value: index },
      {
        value: (score.current.value + medias.length / 2) % medias.length,
        duration: options.duration,
        ease: options.ease,
        overwrite: 'auto',
        onUpdate: () => setIndex(gsap.utils.clamp(0, medias.length - 1, Math.ceil(score.current.value))),
        onComplete: () => (randomized.current = shuffle(indexes.current)),
      }
    )

  useUpdateEffect(() => handleClick(), [cycle])

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1.5,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  }

  const image = useMemo(
    () =>
      medias.map((img, i) => (
        <Lazy key={uid + i} inview={{ once: true }} eager={!!(i <= 6)}>
          {({ ref, inview }) => (
            <StoryblokImage
              ref={ref}
              data={img}
              inview={inview}
              sx={{ variant: 'hero.image' }}
              object={{ fit: 'contain', position: 'center center' }}
              data-index={i}
              fitted={true}
              fade={false}
            />
          )}
        </Lazy>
      )),
    [assets] // eslint-disable-line
  )

  return (
    <section sx={{ variant: 'hero' }} data-column>
      <div sx={{ variant: 'hero.container' }} data-column>
        <div sx={{ variant: 'hero.body' }}>
          <div sx={{ variant: 'hero.stroke' }} data-gsap="hero-stroke" />

          {head.map((node, i) => (
            <Heading key={i + 'head'} variant="text" cropped={i === 0 ? false : true} data-gsap="hero-stagger">
              {node}
            </Heading>
          ))}

          <motion.div variants={variants} initial="initial" animate="animate">
            <div
              data-view={randomized.current[index]}
              data-length={medias.length}
              onClick={handleClick}
              data-gsap="avatars"
              sx={{
                variant: 'hero.avatars',
                '[data-index]': { visibility: 'hidden' },
                [`[data-index="${randomized.current[index]}"]`]: { visibility: 'visible' },
              }}
            >
              {image}
            </div>
          </motion.div>
        </div>
      </div>

      <Foot links={links} />
    </section>
  )
}

// function randomInt(min, max) {
//   return Math.floor(Math.random() * (max - min + 1) + min)
// }

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export default memo(Hero)
