import { useMemo, createContext, useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { KEYS } from './utils'

const TransitionContext = createContext()

const TransitionProvider = ({ children, exitBeforeEnter = true, lang = {}, transitions = {}, ...props }) => {
  return (
    <TransitionContext.Provider
      value={{
        lang,
        transitions,
        exitBeforeEnter,
        ...props,
      }}
    >
      {children}
    </TransitionContext.Provider>
  )
}

const TransitionPage = ({ children, as = 'main', watch, location = {}, ...props }) => {
  const { exitBeforeEnter, transitions } = useContext(TransitionContext) ?? {}
  const Motion = motion(as)

  const { from, to } = location?.state ?? {}
  const variant_name = transitions?.[`${from}_${to}`] ?? transitions?.[to] ?? transitions?.__default ?? 'fade'
  const variants = transitions?.effects?.[variant_name] ?? {}

  return useMemo(
    () => (
      <AnimatePresence exitBeforeEnter={exitBeforeEnter}>
        <Motion key={watch ?? location?.pathname} variants={variants} {...KEYS} {...props}>
          {children}
        </Motion>
      </AnimatePresence>
    ),
    [watch ?? location?.pathname, variant_name]
  )
}

export const Transition = ({ children, as = 'div', variants, type, watch, exitBeforeEnter, ...props }) => {
  const { exitBeforeEnter: exe, transitions, lang } = useContext(TransitionContext) ?? {}
  const Motion = motion(as)

  const change = typeof watch === 'function' ? watch({ lang }) : watch

  return useMemo(
    () => (
      <AnimatePresence exitBeforeEnter={exitBeforeEnter ?? exe}>
        <Motion
          key={change}
          variants={variants ?? transitions?.effects?.[type ?? '__default'] ?? {}}
          {...KEYS}
          {...props}
        >
          {children}
        </Motion>
      </AnimatePresence>
    ),
    [change, variants, exe]
  )
}

Transition.Provider = TransitionProvider
Transition.Page = TransitionPage

export default Transition
