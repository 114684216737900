import { useState } from 'react'
import { gsap } from 'gsap'

import { useScrollTrigger } from '../../hooks'

const Row = ({ children, offset = 3, inverted = false, spans: [span, diff], sx, ...props }) => {
  const [rerender, setRerender] = useState(0)
  const [ref] = useScrollTrigger(
    ({ node }) => {
      const { parentNode } = node ?? {}
      const item = node.childNodes[0].childNodes[0]

      const container = gsap.getProperty(parentNode)
      const cell = gsap.getProperty(item)

      const width = {
        container: container('width'),
        row: cell('width') * (span + diff),
        cell: cell('width'),
      }

      if (!!!width.cell) {
        setRerender((prev) => prev + 1)
      } else {
        node?.setAttribute('data-hidden', false)
      }

      const setX = gsap.quickTo(node, 'x', { ease: null })
      const x = width.row + width.cell * offset - width.container

      return setX(inverted ? x : -x)
    },
    ({ node }) => ({
      trigger: node,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 0.6,
    }),
    0,
    [span, rerender]
  )

  return (
    <div
      ref={ref}
      data-hidden="true"
      sx={{ variant: 'showcase.row', '--cell-span': span, ...sx }}
      data-inverted={!!inverted}
      {...props}
    >
      <aside>
        {children}
        <aside sx={{ position: 'absolute' }}>{children}</aside>
      </aside>
    </div>
  )
}

export default Row
