import { Heading } from '@monobits/components'
import { multiLink } from '@boiler/utils'

import { Button } from '../../atoms'
import { useData } from '../../hooks'

const Foot = ({ links, sx, ...props }) => {
  const {
    settings: { long_description },
  } = useData() ?? {}

  return (
    <aside sx={{ variant: 'hero.foot', ...sx }} {...props}>
      <Heading as="p" variant="text" sx={{ maxWidth: '72ch' }} className="-show-desktop-block" data-gsap="hero-stagger">
        {long_description}
      </Heading>
      <nav data-gsap="hero-buttons">
        <div>
          {!!links?.[0] && (
            <Button {...multiLink(links?.[0]?.link)} variant="primary" size="slim" sx={{ mt: ['0.85rem', 'unset'] }}>
              {links?.[0]?.text}
            </Button>
          )}
          {!!links?.[1] && (
            <Button variant="primary" size="slim" {...multiLink(links?.[1]?.link)}>
              {links?.[1]?.text}
            </Button>
          )}
        </div>
      </nav>
    </aside>
  )
}

export default Foot
