import { inset } from '@monobits/core'

export default {
  __default: {
    position: 'fixed',
    zIndex: 'modal',
    ...inset(0),

    p: [1, null, 'calc(var(--padding-x) * 1.5)'],
    bg: 'background',
    pointerEvents: 'none',

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',

    visibility: 'visible',
    clipPath: 'inset(var(--clip-path, 0%) 0 0 0)',
    '--clip-path': '0%',
  },
}
