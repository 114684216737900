import SbEditable from 'storyblok-react'

import Component from './Showcase.component'

const Showcase = ({ blok }) => {
  const { first_row, second_row, link, link_text, alignment, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Component rows={[first_row, second_row]} link={{ text: link_text, link }} alignment={alignment} uid={_uid} />
    </SbEditable>
  )
}

export default Showcase
