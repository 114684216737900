import { useRef } from 'react'
import { Box } from 'theme-ui'
import { useConditionalEffect, useIntersectionObserver } from '@react-hookz/web'
import { Heading, Section } from '@monobits/components'
import { multiLink, useMousePos } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import { Rte } from '../../atoms'
import { MapBloks } from '../../bloks'
import { useTween } from '../../hooks'

const PromoFull = ({ blok, sanitize = (p) => p, ...props }) => {
  const { title, caption, cta_text, cta_link = {}, asset, type: view = 'full', _uid } = blok ?? {}

  const [tween, cursor] = useTween(({ gsap, node }) =>
    gsap
      .timeline({ paused: true, defaults: { duration: 0.45, delay: 0.15, ease: 'power3.inOut', overwrite: 'auto' } })
      .fromTo(node, { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
  )

  const {
    is,
    hover: [hover, setHover],
  } = useMousePos([cursor.current?.parentNode, cursor.current], {
    options: { lerp: 0.45 },
  })

  const dynamic = !!cta_text && !!('id' in (cta_link ?? {}))

  useConditionalEffect(
    () => {
      tween?.[!!hover ? 'play' : 'reverse']()
    },
    [hover],
    [!!!is.touch, tween, view === 'full', !!dynamic]
  )

  const link = multiLink(cta_link ?? {})

  let containerRef = useRef(null)
  const intersection = useIntersectionObserver(containerRef, { threshold: [0, 0.5] })

  const media = (
    <MapBloks
      items={asset}
      childProps={{
        video: {
          isActive: !!intersection?.isIntersecting,
          placeholder: true,
        },
      }}
      id={_uid}
      {...sanitize(props)}
    />
  )

  return (
    <SbEditable content={blok} key={_uid}>
      <Section data-view="full" variant="promo" sx={{ variant: 'promo' }}>
        <aside sx={{ variant: 'promo.head' }}>
          <Rte data-scope="caption" content={title} variant="text" />
          <Rte data-scope="description" content={caption} variant="text" />
        </aside>

        <Box
          ref={containerRef}
          sx={{ variant: 'promo.asset' }}
          {...(!!!is.touch && link)}
          onMouseEnter={!!dynamic ? () => setHover(true) : null}
          onMouseLeave={!!dynamic ? () => setHover(false) : null}
        >
          <Heading
            ref={cursor.ref}
            variant="caption"
            sx={{ variant: 'promo.cursor' }}
            aria-hidden={true}
            {...(!!is.touch && link)}
          >
            {cta_text}
          </Heading>

          <div
            data-column
            sx={{
              borderRadius: 'var(--padding-x)',
              transform: 'translateZ(0)',
              overflow: 'hidden',
            }}
          >
            {media}
          </div>
        </Box>
      </Section>
    </SbEditable>
  )
}

export default PromoFull
